import React from 'react';
import './OkModal.css'; // You should create a CSS file for this component

function Modal({ title, children, visible, onOk, onCancel }) {
  if (!visible) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>{title}</h2>
        <div className="modal-content">
          {children}
        </div>
        <div className="modal-actions">
          <button onClick={onOk}>OK</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
