export const states = [
    { abbreviation: 'AL', name: 'Alabama' },
    { abbreviation: 'AK', name: 'Alaska' },
    { abbreviation: 'AZ', name: 'Arizona' },
    { abbreviation: 'AR', name: 'Arkansas' },
    { abbreviation: 'CA', name: 'California' },
    { abbreviation: 'CO', name: 'Colorado' },
    { abbreviation: 'CT', name: 'Connecticut' },
    { abbreviation: 'DE', name: 'Delaware' },
    { abbreviation: 'FL', name: 'Florida' },
    { abbreviation: 'GA', name: 'Georgia' },
    { abbreviation: 'HI', name: 'Hawaii' },
    { abbreviation: 'ID', name: 'Idaho' },
    { abbreviation: 'IL', name: 'Illinois' },
    { abbreviation: 'IN', name: 'Indiana' },
    { abbreviation: 'IA', name: 'Iowa' },
    { abbreviation: 'KS', name: 'Kansas' },
    { abbreviation: 'KY', name: 'Kentucky' },
    { abbreviation: 'LA', name: 'Louisiana' },
    { abbreviation: 'ME', name: 'Maine' },
    { abbreviation: 'MD', name: 'Maryland' },
    { abbreviation: 'MA', name: 'Massachusetts' },
    { abbreviation: 'MI', name: 'Michigan' },
    { abbreviation: 'MN', name: 'Minnesota' },
    { abbreviation: 'MS', name: 'Mississippi' },
    { abbreviation: 'MO', name: 'Missouri' },
    { abbreviation: 'MT', name: 'Montana' },
    { abbreviation: 'NE', name: 'Nebraska' },
    { abbreviation: 'NV', name: 'Nevada' },
    { abbreviation: 'NH', name: 'New Hampshire' },
    { abbreviation: 'NJ', name: 'New Jersey' },
    { abbreviation: 'NM', name: 'New Mexico' },
    { abbreviation: 'NY', name: 'New York' },
    { abbreviation: 'NC', name: 'North Carolina' },
    { abbreviation: 'ND', name: 'North Dakota' },
    { abbreviation: 'OH', name: 'Ohio' },
    { abbreviation: 'OK', name: 'Oklahoma' },
    { abbreviation: 'OR', name: 'Oregon' },
    { abbreviation: 'PA', name: 'Pennsylvania' },
    { abbreviation: 'RI', name: 'Rhode Island' },
    { abbreviation: 'SC', name: 'South Carolina' },
    { abbreviation: 'SD', name: 'South Dakota' },
    { abbreviation: 'TN', name: 'Tennessee' },
    { abbreviation: 'TX', name: 'Texas' },
    { abbreviation: 'UT', name: 'Utah' },
    { abbreviation: 'VT', name: 'Vermont' },
    { abbreviation: 'VA', name: 'Virginia' },
    { abbreviation: 'WA', name: 'Washington' },
    { abbreviation: 'WV', name: 'West Virginia' },
    { abbreviation: 'WI', name: 'Wisconsin' },
    { abbreviation: 'WY', name: 'Wyoming' },
    { abbreviation: 'AA', name: 'Not Listed' },
  ];

  export const countries = [
    { abbreviation: 'AF', name: 'Afghanistan' },
    { abbreviation: 'AL', name: 'Albania' },
    { abbreviation: 'DZ', name: 'Algeria' },
    { abbreviation: 'AD', name: 'Andorra' },
    { abbreviation: 'AO', name: 'Angola' },
    { abbreviation: 'AG', name: 'Antigua and Barbuda' },
    { abbreviation: 'AR', name: 'Argentina' },
    { abbreviation: 'AM', name: 'Armenia' },
    { abbreviation: 'AU', name: 'Australia' },
    { abbreviation: 'AT', name: 'Austria' },
    { abbreviation: 'AZ', name: 'Azerbaijan' },
    { abbreviation: 'BS', name: 'Bahamas' },
    { abbreviation: 'BD', name: 'Bangladesh' },
    { abbreviation: 'BB', name: 'Barbados' },
    { abbreviation: 'BY', name: 'Belarus' },
    { abbreviation: 'BE', name: 'Belgium' },
    { abbreviation: 'BZ', name: 'Belize' },
    { abbreviation: 'BJ', name: 'Benin' },
    { abbreviation: 'BT', name: 'Bhutan' },
    { abbreviation: 'BO', name: 'Bolivia' },
    { abbreviation: 'BA', name: 'Bosnia and Herzegovina' },
    { abbreviation: 'BR', name: 'Brazil' },
    { abbreviation: 'BN', name: 'Brunei' },
    { abbreviation: 'BG', name: 'Bulgaria' },
    { abbreviation: 'BF', name: 'Burkina Faso' },
    { abbreviation: 'BI', name: 'Burundi' },
    { abbreviation: 'KH', name: 'Cambodia' },
    { abbreviation: 'CM', name: 'Cameroon' },
    { abbreviation: 'CA', name: 'Canada' },
    { abbreviation: 'CF', name: 'Central African Republic' },
    { abbreviation: 'TD', name: 'Chad' },
    { abbreviation: 'CL', name: 'Chile' },
    { abbreviation: 'CN', name: 'China' },
    { abbreviation: 'CO', name: 'Colombia' },
    { abbreviation: 'CR', name: 'Costa Rica' },
    { abbreviation: 'HR', name: 'Croatia' },
    { abbreviation: 'CU', name: 'Cuba' },
    { abbreviation: 'CY', name: 'Cyprus' },
    { abbreviation: 'CZ', name: 'Czech Republic' },
    { abbreviation: 'DK', name: 'Denmark' },
    { abbreviation: 'DJ', name: 'Djibouti' },
    { abbreviation: 'DM', name: 'Dominica' },
    { abbreviation: 'DO', name: 'Dominican Republic' },
    { abbreviation: 'EC', name: 'Ecuador' },
    { abbreviation: 'EG', name: 'Egypt' },
    { abbreviation: 'SV', name: 'El Salvador' },
    { abbreviation: 'GQ', name: 'Equatorial Guinea' },
    { abbreviation: 'ER', name: 'Eritrea' },
    { abbreviation: 'EE', name: 'Estonia' },
    { abbreviation: 'ET', name: 'Ethiopia' },
    { abbreviation: 'FJ', name: 'Fiji' },
    { abbreviation: 'FI', name: 'Finland' },
    { abbreviation: 'FR', name: 'France' },
    { abbreviation: 'GA', name: 'Gabon' },
    { abbreviation: 'GM', name: 'Gambia' },
    { abbreviation: 'GE', name: 'Georgia' },
    { abbreviation: 'DE', name: 'Germany' },
    { abbreviation: 'GH', name: 'Ghana' },
    { abbreviation: 'GR', name: 'Greece' },
    { abbreviation: 'GD', name: 'Grenada' },
    { abbreviation: 'GT', name: 'Guatemala' },
    { abbreviation: 'GN', name: 'Guinea' },
    { abbreviation: 'GW', name: 'Guinea-Bissau' },
    { abbreviation: 'GY', name: 'Guyana' },
    { abbreviation: 'HK', name: 'Hong Kong'},
    { abbreviation: 'HT', name: 'Haiti' },
    { abbreviation: 'HN', name: 'Honduras' },
    { abbreviation: 'HU', name: 'Hungary' },
    { abbreviation: 'IS', name: 'Iceland' },
    { abbreviation: 'IN', name: 'India' },
    { abbreviation: 'ID', name: 'Indonesia' },
    { abbreviation: 'IR', name: 'Iran' },
    { abbreviation: 'IQ', name: 'Iraq' },
    { abbreviation: 'IE', name: 'Ireland' },
    { abbreviation: 'IL', name: 'Israel' },
    { abbreviation: 'IT', name: 'Italy' },
    { abbreviation: 'JM', name: 'Jamaica' },
    { abbreviation: 'JP', name: 'Japan' },
    { abbreviation: 'JO', name: 'Jordan' },
    { abbreviation: 'KZ', name: 'Kazakhstan' },
    { abbreviation: 'KE', name: 'Kenya' },
    { abbreviation: 'KW', name: 'Kuwait' },
    { abbreviation: 'KG', name: 'Kyrgyzstan' },
    { abbreviation: 'LV', name: 'Latvia' },
    { abbreviation: 'LB', name: 'Lebanon' },
    { abbreviation: 'LR', name: 'Liberia' },
    { abbreviation: 'LY', name: 'Libya' },
    { abbreviation: 'LT', name: 'Lithuania' },
    { abbreviation: 'LU', name: 'Luxembourg' },
    { abbreviation: 'MK', name: 'Macedonia' },
    { abbreviation: 'MG', name: 'Madagascar' },
    { abbreviation: 'MW', name: 'Malawi' },
    { abbreviation: 'MY', name: 'Malaysia' },
    { abbreviation: 'MV', name: 'Maldives' },
    { abbreviation: 'ML', name: 'Mali' },
    { abbreviation: 'MT', name: 'Malta' },
    { abbreviation: 'MH', name: 'Marshall Islands' },
    { abbreviation: 'MR', name: 'Mauritania' },
    { abbreviation: 'MU', name: 'Mauritius' },
    { abbreviation: 'MX', name: 'Mexico' },
    { abbreviation: 'FM', name: 'Micronesia' },
    { abbreviation: 'MD', name: 'Moldova' },
    { abbreviation: 'MC', name: 'Monaco' },
    { abbreviation: 'MN', name: 'Mongolia' },
    { abbreviation: 'ME', name: 'Montenegro' },
    { abbreviation: 'MA', name: 'Morocco' },
    { abbreviation: 'MZ', name: 'Mozambique' },
    { abbreviation: 'MM', name: 'Myanmar (Burma)' },
    { abbreviation: 'NA', name: 'Namibia' },
    { abbreviation: 'NR', name: 'Nauru' },
    { abbreviation: 'NP', name: 'Nepal' },
    { abbreviation: 'NL', name: 'Netherlands' },
    { abbreviation: 'NZ', name: 'New Zealand' },
    { abbreviation: 'NI', name: 'Nicaragua' },
    { abbreviation: 'NE', name: 'Niger' },
    { abbreviation: 'NG', name: 'Nigeria' },
    { abbreviation: 'NO', name: 'Norway' },
    { abbreviation: 'OM', name: 'Oman' },
    { abbreviation: 'PK', name: 'Pakistan' },
    { abbreviation: 'PW', name: 'Palau' },
    { abbreviation: 'PA', name: 'Panama' },
    { abbreviation: 'PG', name: 'Papua New Guinea' },
    { abbreviation: 'PY', name: 'Paraguay' },
    { abbreviation: 'PE', name: 'Peru' },
    { abbreviation: 'PH', name: 'Philippines' },
    { abbreviation: 'PL', name: 'Poland' },
    { abbreviation: 'PT', name: 'Portugal' },
    { abbreviation: 'QA', name: 'Qatar' },
    { abbreviation: 'RO', name: 'Romania' },
    { abbreviation: 'RU', name: 'Russia' },
    { abbreviation: 'RW', name: 'Rwanda' },
    { abbreviation: 'KN', name: 'Saint Kitts and Nevis' },
    { abbreviation: 'LC', name: 'Saint Lucia' },
    { abbreviation: 'VC', name: 'Saint Vincent and the Grenadines' },
    { abbreviation: 'WS', name: 'Samoa' },
    { abbreviation: 'SM', name: 'San Marino' },
    { abbreviation: 'ST', name: 'São Tomé and Príncipe' },
    { abbreviation: 'SA', name: 'Saudi Arabia' },
    { abbreviation: 'SN', name: 'Senegal' },
    { abbreviation: 'RS', name: 'Serbia' },
    { abbreviation: 'SC', name: 'Seychelles' },
    { abbreviation: 'SG', name: 'Singapore' },
    { abbreviation: 'SK', name: 'Slovakia' },
    { abbreviation: 'SI', name: 'Slovenia' },
    { abbreviation: 'SB', name: 'Solomon Islands' },
    { abbreviation: 'SO', name: 'Somalia' },
    { abbreviation: 'ZA', name: 'South Africa' },
    { abbreviation: 'ES', name: 'Spain' },
    { abbreviation: 'LK', name: 'Sri Lanka' },
    { abbreviation: 'SD', name: 'Sudan' },
    { abbreviation: 'SR', name: 'Suriname' },
    { abbreviation: 'SE', name: 'Sweden' },
    { abbreviation: 'CH', name: 'Switzerland' },
    { abbreviation: 'SY', name: 'Syria' },
    { abbreviation: 'TJ', name: 'Tajikistan' },
    { abbreviation: 'TZ', name: 'Tanzania' },
    { abbreviation: 'TH', name: 'Thailand' },
    { abbreviation: 'TL', name: 'Timor-Leste' },
    { abbreviation: 'TG', name: 'Togo' },
    { abbreviation: 'TO', name: 'Tonga' },
    { abbreviation: 'TT', name: 'Trinidad and Tobago' },
    { abbreviation: 'TN', name: 'Tunisia' },
    { abbreviation: 'TR', name: 'Turkey' },
    { abbreviation: 'TM', name: 'Turkmenistan' },
    { abbreviation: 'TV', name: 'Tuvalu' },
    { abbreviation: 'UG', name: 'Uganda' },
    { abbreviation: 'UA', name: 'Ukraine' },
    { abbreviation: 'AE', name: 'United Arab Emirates' },
    { abbreviation: 'GB', name: 'United Kingdom' },
    { abbreviation: 'US', name: 'United States' },
    { abbreviation: 'UY', name: 'Uruguay' },
    { abbreviation: 'UZ', name: 'Uzbekistan' },
    { abbreviation: 'VU', name: 'Vanuatu' },
    { abbreviation: 'VE', name: 'Venezuela' },
    { abbreviation: 'VN', name: 'Vietnam' },
    { abbreviation: 'YE', name: 'Yemen' },
    { abbreviation: 'ZM', name: 'Zambia' },
    { abbreviation: 'ZW', name: 'Zimbabwe' }
  ];
