import React from 'react';
import lipsync1 from '../assets/lipsync1.png';
import lipsync2 from '../assets/lipsync2.png';
import lipsync3 from '../assets/lipsync3.png';
import lipsyncVideo from '../assets/lipsync.mp4';

import './LipSyncDocumentation.css';


export function LipSyncDocumentation() {
    return (
        <div>
          <div className="title">
            <h1>How to Use lipsync Commands with Our Discord Bot</h1>
          </div>
          <div className="container">
            <h2>Step 1: Choose the Right lipsync Generation Channel</h2>
            <p>First, you'll need to navigate to the following lipsync generation channel on our Discord server:</p>
            <div className="tip-box">
              <p>📹・lipsync-gen</p>
            </div>
            <br /><br />
            <div>
              <h2>lipsync Generation Command</h2>
            </div>
            <div className="tip-box">
              <ul>
                <li><b style={{ color: '#FE78FD' }}>/lipsync</b> - Generates an AI video based on an uploaded image and audio files. This command combines the provided image and audio to create a realistic video.</li>
              </ul>
            </div>
            <br /><br />
            <div>
              <h2>Step 2: Enter Your Command</h2>
              <p>Once you've selected the lipsync-gen channel, you can create your video generation by using the /lipsync command.</p>
            </div>
            <div className="tip-box">
              <img src={lipsync1} alt="lipsync Example 1" />
            </div>
            <div>
              <br />
              Unlike other commands, the lipsync command requires you to upload files to the Discord channel. You can do this by dragging and dropping the files into the channel, or by clicking the upload button and selecting the files from your computer. You also need to add the audio file to the second upload file by following the same instructions. Here's an example of how to use the command:
              <br /><br />
            </div>
            <div className="tip-box">
              <p><b style={{ color: '#FE78FD' }}>Example:</b></p>
              <br /><br />
              <img src={lipsync2} alt="lipsync Example 2" />
            </div>
            <br /><br />
            <div>
              <h2>Step 3: Wait for the Response</h2>
              <p>Once you enter your command and hit enter, the bot will return a message that says "Your request is in queue! We will notify you when it is ready."</p>
            </div>
            <div className="tip-box">
              <img src={lipsync3} alt="lipsync Example 3" />
            </div>
            <br /><br />
            <div>
              <h2>Step 4: Receive Your Generated Video</h2>
              <p>Your prompt will go into the queue in the order it was received. When the bot finishes creating your video, it will post it in the lipsync-gen room and ping you with a notification letting you know it's ready.</p>
            </div>
            <div className="tip-box">
              <h3>lipsync Results:</h3>
              <video width="640" controls>
                <source src={lipsyncVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <br /><br />
            <div>
              <h3>Conclusion</h3>
              <p>The /lipsync command provides a versatile way to create realistic videos using uploaded images and audio files. Do not use to this to willingingly deceive but explore and enjoy this feature within our Discord community, and unleash your creativity with video! 📹✨</p>
            </div>
          </div>
        </div>
      );
};