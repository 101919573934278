import React, { useContext } from 'react';
import axios from 'axios';
import { GetVersion, getUserDetails, getSubscriptionDetails, isUpgrade } from '../../Utils/api.js';
import { useNavigate } from 'react-router-dom';
import { useLocation, Link } from 'react-router-dom';
import SubscriptionContext from '../../Component/SubscriptionContext';
import './index.css';

// Function to parse query parameters
const parseQueryParams = (query) => {
  return query
    .substring(1) // Remove the '?' at the start of the query string
    .split('&')
    .reduce((params, param) => {
      let [key, value] = param.split('=');
      params[key] = value;
      return params;
    }, {});
};



export function SubscriptionSelectionPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = React.useState(null);
  const [subscription, setSubscription] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const subscriptionTiers = useContext(SubscriptionContext);
  const [recurring_type, setRecurringType] = React.useState("month");
  
  const queryParams = parseQueryParams(location.search);
  const passedPurchaseType = queryParams.purchaseType;

  const getSubscriptionStatus = (tierName) => {
    if (!subscription || user.subscription_status === 'free') return '';

    if (user.subscription_status === tierName) {
      return 'You have';
    } else if (isUpgrade(user.subscription_status, tierName)) {
      return 'Upgrade to';
    } else {
      return 'Downgrade to';
    }
  };

  const getCardClass = (tierName) => {
    if (!subscription || user.subscription_status === 'free') return 'tier-card';

    return user.subscription_status === tierName ? 'tier-card selected' : 'tier-card';
  };
  
  const getCardClassByName = (tierName) => { 
    if (!subscription) return 'tier-card';
  
    if (user.subscription_status === 'free') {
      return 'tier-card';
    }
    
    if (subscription.subscription_id === tierName) {
      return 'tier-card selected';
    }
  
    return 'tier-card';
  };

  const handleSubscriptionSelection = async (tierType,tierPrice, e) => {
    e.preventDefault();

    if (user && user.subscription_status !== 'free' && passedPurchaseType !== 'Gift') {
      console.log('User already has a subscription, call updateSubscription');
      console.log('tierType: ',tierType);
      console.log("user.stripeid: ",user.stripeid);
      
      // make an axios call to getSubscriptionDetails with the user.stripeid
      // if the subscription exists, then call updateSubscription
      // if the subscription doesn't exist, then proceed with the current flow
      const { data: oldSubData } = await getSubscriptionDetails(user.stripeid);
      if (oldSubData)
      {
        console.log('Subscription exists:', oldSubData);
        console.log('Actua price is:', oldSubData.actualPrice);
      }   

      // User already has a subscription, call updateSubscription
      try {
        const response = await axios.post('/api/payment/updateSubscription', {
          userId: user.user_id,
          stripeId: user.stripeid,
          newsubscriptiontype: tierType,
        });
        if (response.status === 200) {
          // Handle successful subscription update  
          console.log('Subscription updated:', response.data.updatedSubscription);

          // /api/payment/updateSubscription returns this: res.status(200).json({ updatedSubscription});
          // response.data will contain the new subscription object
          // The user just successfully updated their subscription, so show an alert window that tells
          // them what action just happened to their subscription and the new status of their subscription
          // So if they upgraded, it will say "You have upgraded to: <tierType>" and if they downgraded
          // say "Your subscription will downgrade at <billing cycle end date> to: <tierType>"
          // and then navigate them back to the dashboard
          console.log("subscription is: ",response.data.updatedSubscription);
          console.log("-- plan is: ",response.data.updatedSubscription.plan);
          const subscription = response.data.updatedSubscription;
          const oldSubscriptionType = oldSubData.subscription_status // The current type before update
          const newSubscriptionType = tierType; // The new type after update
          
          let subscriptionStatusMessage = '';
          
          if (isUpgrade(oldSubscriptionType, newSubscriptionType)) {
            subscriptionStatusMessage = `You have upgraded to: ${newSubscriptionType}. An invoice will be sent to you shortly at ${user.email}`;
          } else if (oldSubscriptionType === newSubscriptionType) {
            subscriptionStatusMessage = `You are still on the same subscription tier: ${newSubscriptionType}`;
          } else {
            subscriptionStatusMessage = `Your subscription will downgrade at ${new Date(subscription.current_period_end * 1000).toLocaleDateString()} to: ${newSubscriptionType}`;
          }
          
          alert(subscriptionStatusMessage);
          navigate(`/dashboard?${process.env.REACT_APP_CURRENT_VERSION}`);
        }
      } catch (error) {
        console.error('Error updating subscription:', error);
        // Handle error
      }
    } else {
      // User doesn't have a subscription, proceed with the current flow
      e.currentTarget.submit();
    }
  };


  React.useEffect(() => {
    const fetchUserAndSubscriptionDetails = async () => {
      try {
        setLoading(true);
        const { data } = await getUserDetails();
        setUser(data);
       
        // If this is a gift purchase, then we won't load the user's current subscription to prevent the page from
        // showing the user's current subscription status or any other information about the user's current subscription
        if (passedPurchaseType === 'Gift') {
          console.log("This is a gift purchase, so we won't load the user's current subscription");
          setLoading(false);
        }
        else
        if (data.stripeid) {
          const { data: subscriptionData } = await getSubscriptionDetails(data.stripeid);
          if (subscriptionData) {
            setLoading(false);
            setSubscription(subscriptionData);
          } else {
            setSubscription(null);
          }
        }
      } catch (err) {
        navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
        setLoading(false);
      }
    };

    fetchUserAndSubscriptionDetails();
  }, [navigate]);

  return (
    <div className="payment-page">
      <h1>{passedPurchaseType === 'Gift' ? "Subscription tier to give as a Gift ":"Choose a subscription tier"}</h1>
      {loading ? (
        <div className="fullscreen-spinner">
          <div className="spinner" id="spinner"></div>
        </div>
      ) : (
        <div className="tiers-container">
          {subscriptionTiers.map((tier, index) => (
            <div key={index}>
              <p className="tier-status">{getSubscriptionStatus(tier.tier)}</p>
              <form 
                action="/api/payment/create-checkout-session" 
                method="POST" 
                className={getCardClass(tier.tier)}
                style={getCardClass(tier.tier) !== 'tier-card selected' ? { backgroundColor: tier.color } : {}}
                onClick={(e) => handleSubscriptionSelection(tier.tier, tier.price, e)}
              >
                {Object.entries(tier).map(([key, value], entryIndex) => {
                  if (key === 'color' || key === 'ptype' || key === 'oCost') {
                    return null;
                  }

                  if (entryIndex === 0) {
                    return (
                      <h3
                        key={key}
                        className={`tier-title ${
                          getCardClass(tier.price) === 'tier-card selected'
                            ? 'selected-tier-name'
                            : ''
                        }`}
                      >
                        {value}
                      </h3>
                    );
                  }

                  if (key === 'credits') {
                    return (
                      <p key={key}>
                        <span className="attribute-name">{key}:</span> {value > 14999 ? 'Unlimited' : value}
                      </p>
                    );
                  }

                  if (key === 'details') {
                    return <p key={key} dangerouslySetInnerHTML={{ __html: value }} />;
                  }

                  if (key === 'price') {
                    if (tier.ptype === 'Yearly') {
                      return (
                        <div key={key}>
                          <p className="old-price" style={{ textDecoration: 'line-through' }}>
                            {tier.oCost} /Month
                          </p>
                          <p>
                            ${((parseFloat(tier.price.replace('$', '')) / 12).toFixed(2))} /month
                          </p>
                        </div>
                      );
                    } else if (tier.ptype === 'Monthly') {
                      return <p key={key}>{value} /month</p>;
                    }
                  }

                  return (
                    <p key={key}>
                      <span className="attribute-name">{key}:</span> {value}
                    </p>
                  );
                })}

                {/* Hidden inputs for form submission */}
                <input type="hidden" name="email" value={user.email ||''} />
                <input type="hidden" name="userId" value={user.user_id} />
                <input type="hidden" name="stripeId" value={user.stripeid} />
                <input type="hidden" name="purchaseType" value={passedPurchaseType === 'Gift' ? 'Gift' : 'Subscription'} />
                <input type="hidden" name="tierType" value={tier.tier} />
                <input type="hidden" name="recurringType" value={recurring_type} />
              </form>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}