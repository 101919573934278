import React, { useRef } from 'react';
import styles from './LoraGuidePage.module.css';


import lorapng from '../assets/lora.png';
import subjectImg1 from '../assets/subject.jpg';
import subjectImg2 from '../assets/subject2.jpg';
import subjectImg3 from '../assets/subject3.jpg';
import outputImg1_1 from '../assets/output1.png';
import outputImg1_2 from '../assets/output2.png';
import outputImg1_3 from '../assets/output3.png';
import outputImg2_1 from '../assets/output4.png'; // Update these paths if necessary
import outputImg2_2 from '../assets/output5.png'; // Update these paths if necessary
import outputImg2_3 from '../assets/output6.png'; // Update these paths if necessary
import outputImg3_1 from '../assets/output7.png'; // Update these paths if necessary
import outputImg3_2 from '../assets/output8.png'; // Update these paths if necessary
import outputImg3_3 from '../assets/output9.png'; // Update these paths if necessary

export const LoraGuidePage = () => {
  const modalRef = useRef(null);
  const modalImgRef = useRef(null);

  const openModal = (imageSrc) => {
    modalImgRef.current.src = imageSrc;
    modalRef.current.style.display = "block";
  };

  const closeModal = () => {
    modalRef.current.style.display = "none";
  };

  return (
    <div className={styles.body}>
      <div className={styles.title}>
        <h1>How to Train and Use LoRA Models with CraftFul Bot</h1>
      </div>
      <div className={styles.container}>
        <div className={styles.h2}>Introduction</div>
        <p>Welcome to the comprehensive guide on how to use the groundbreaking LoRA feature in the CraftFul bot! LoRA (Low Rank Adaptation) enables you to train your custom image models easily.</p>

        <div className={styles.h2}>Step 1: Training Your LoRA</div>
        <p>Training a LoRA model requires you to provide images and name your model.</p>
        <div className={styles.tipBox}>
          <ul>
            <li><b style={{color: '#FE78FD'}}>/loracreate</b> - Create a LoRA model using eight individual images.</li>
            <li><b style={{color: '#FE78FD'}}>/loracreatefromzip</b> - Create a LoRA model using a .ZIP file containing 8-40 images.</li>
            <br/>
            <b style={{color: '#FE78FD'}}>NOTE:</b> The files must be png or jpg only. It takes about 15 minutes to create the lora. You will get a DM when it is done.
          </ul>
        </div>
        
        <div className={styles.h2}>Step 2: Managing Your LoRAs</div>
        <p>You can view or remove your trained LoRAs.</p>
        <div className={styles.tipBox}>
          <ul>
            <li><b style={{color: '#FE78FD'}}>/myloras</b> - View a list of your created LoRA models.</li>
            <li><b style={{color: '#FE78FD'}}>/remlora</b> - Delete one of your created LoRA models.</li>
          </ul>
        </div>

        <div className={styles.h2}>Step 3: Using Your LoRAs</div>
        <p>
          To use your trained LoRAs, click on the "+5 more" option next to the prompt box that appears when you type /make.<br/><br/>
          Select "lora" from the list to add a LoRA input box. Enter the name of your LoRA model here.<br/>
          <b style={{color: '#FE78FD'}}>NOTE:</b> This is case sensitive<br/><br/>
          You can also adjust the LoRA weight to change how much the LoRA style influences your image. It defaults to 0.85; the highest setting is 1 and the lowest is 0.1.
        </p>
        
        <div className={styles.tipBox}>
          <p><b style={{color: '#FE78FD'}}>Example:</b> /make [Your prompt] -lora [Your LoRA model name] -loraweight [0.1-1]</p>
          <img src={lorapng} className={styles.img} alt="LoRA example"/>
        </div>

        <div className={styles.h2}>Step 4: Wait for Your Image</div>
        <p>After running the command, the bot will take approximately 60 seconds or so and you will get a notification when your image is ready.</p>
        
        <div className={styles.container}>
          <div className={styles.h2}>Sample Section</div>

          {/* Row 1 */}
          <div>
            <div className={styles.h3}>Subject 1:</div>
            <img src={subjectImg1} className={styles.thumbnail} alt="Subject 1" onClick={() => openModal(subjectImg1)}/>
          </div>
          <div>
            <div className={styles.h3}>Output 1:</div>
            <img src={outputImg1_1} className={styles.thumbnail} alt="Output 1.1" onClick={() => openModal(outputImg1_1)}/>
            <img src={outputImg1_2} className={styles.thumbnail} alt="Output 1.2" onClick={() => openModal(outputImg1_2)}/>
            <img src={outputImg1_3} className={styles.thumbnail} alt="Output 1.3" onClick={() => openModal(outputImg1_3)}/>
          </div>

          {/* Row 2 */}
          <div>
            <div className={styles.h3}>Subject 2:</div>
            <img src={subjectImg2} className={styles.thumbnail} alt="Subject 2" onClick={() => openModal(subjectImg2)}/>
          </div>
          <div>
            <div className={styles.h3}>Output 2:</div>
            <img src={outputImg2_1} className={styles.thumbnail} alt="Output 2.1" onClick={() => openModal(outputImg2_1)}/>
            <img src={outputImg2_2} className={styles.thumbnail} alt="Output 2.2" onClick={() => openModal(outputImg2_2)}/>
            <img src={outputImg2_3} className={styles.thumbnail} alt="Output 2.3" onClick={() => openModal(outputImg2_3)}/>
          </div>

          {/* Row 3 */}
          <div>
            <div className={styles.h3}>Subject 3:</div>
            <img src={subjectImg3} className={styles.thumbnail} alt="Subject 3" onClick={() => openModal(subjectImg3)}/>
          </div>
          <div>
            <div className={styles.h3}>Output 3:</div>
            <img src={outputImg3_1} className={styles.thumbnail} alt="Output 3.1" onClick={() => openModal(outputImg3_1)}/>
            <img src={outputImg3_2} className={styles.thumbnail} alt="Output 3.2" onClick={() => openModal(outputImg3_2)}/>
            <img src={outputImg3_3} className={styles.thumbnail} alt="Output 3.3" onClick={() => openModal(outputImg3_3)}/>
          </div>
        </div>

      </div>
      
      <div ref={modalRef} className={styles.modal}>
        <span className={styles.close} onClick={closeModal}>&times;</span>
        <img ref={modalImgRef} className={styles.modalContent} alt="modal"/>
      </div>
    </div>
  );
};

