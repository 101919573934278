import React from 'react';
import './Documentation.css';

export function Documentation() {
    return (
        <div>
          <div style={{ textAlign: 'center' }}>
            <h1>CraftFul Help Guide</h1>
            <h2>Welcome to CraftFul, your complete suite of AI Creation tools at your fingertips! This guide will provide you with detailed information about the commands and functions available in CraftFul. Please use these commands responsibly and in the appropriate channels. If you encounter any issues or have any questions, feel free to reach out to the server moderators or administrators for assistance. Enjoy your creative journey with CraftFul!</h2>
          </div>
          <h1>CraftFul Help Guide</h1>
          <h2>General Commands</h2>
          <ul>
            <li><code>/help</code> - Displays on-the-fly help information in any room.</li>
          </ul>
          <h2>Video Generation Commands</h2>
          <ul>
            <li><code>/video</code> - Creates an AI video from text.</li>
            <li><code>/batchvideo</code> - Generates two similar but uniquely different video clips.</li>
            <li><code>/rawbatchvideo</code> - Will get you the raw access to the model which will result in way more movement at the expense of visual fidelity. It is the raw version of one of the video models with no middle work between the model and the output.  it gives you it in its most raw basic form. its for people who want all the movement so that they can tweak and edit and slow it down at their preference.</li>
            <li><code>/movie</code> - Creates an AI Movie with Audio and Visuals from the text entered.</li>
            <li><code>/lipsync</code> - Generates an AI video based on an uploaded image and audio files. This command combines the provided image and audio to create a video.</li>
          </ul>
          <h2>Image Generation Commands</h2>
          <ul>
            <li><code>/make &lt;prompt&gt;</code> - Generates a 2x2 image grid based on the provided image prompt.</li>
            <li><code>/describe</code> - Uploads an image and generates a prompt based on the image content.</li>
            <li><code>/blend</code> - Blends two images together to create a new unique image.</li>
          </ul>
          <h2>Image Weight Commands</h2>
          <ul>
            <li><code>(this)</code> - Gives the word/phrase a 25% boost in strength.</li>
            <li><code>((this))</code> - Gives the word/phrase a 50% boost in strength.</li>
            <li><code>(this:0.6)</code> - Adds 60% strength to the word/phrase.</li>
            <li><code>(this:1.5)</code> - Adds 150% strength to the word/phrase.</li>
          </ul>
          <h2>Image Manipulation Commands</h2>
          <ul>
            <li><code>/Change</code> - Swaps out the person in a given image with another person of your choice.</li>
            <li><code>/adjust</code> - Adjusts things such as expressions, emotions, or details in an image.</li>
          </ul>
          <h2>Options for the /Change command:</h2>
          <ul>
            <li>CFG - Adds more weight to the image.</li>
            <li>Denoising - Removes weight from the image. Setting a lower denoising value retains more of the original image. A value of 0.1 represents a 10% change, while 0.9 represents almost an entirely new image.</li>
          </ul>
          <h2>Image Generation Flags</h2>
          <ul>
            <li><code>-wide</code> - Specifies an aspect ratio of 16:9.</li>
            <li><code>-tall</code> - Specifies an aspect ratio of 9:16.</li>
            <li><code>-34</code> - Specifies an aspect ratio of 3:4.</li>
            <li><code>-43</code> - Specifies an aspect ratio of 4:3.</li>
            <li><code>-tile</code> - Creates an image in tile format, great for wallpapers and backgrounds.</li>
          </ul>
          <h2>New Generative Fill Commands</h2>
          <ul>
            <li><code>/filltall</code> - Adds a 256px extension to your image between the top and bottom edges.</li>
            <li><code>/fillwide</code> - Adds a 256px extension to your image between the left and right edges.</li>
            <li><code>/fillboth</code> - Adds a 256px extension to your image on all sides.</li>
          </ul>
          <h2>Emoji-Interaction Commands</h2>
          <p>In CraftFul, you can interact with the generated image grid using emoji buttons under each image. Here's what each emoji does:</p>
          <ul>
            <li><code>🔀</code> - Vary: Clicking on this emoji applies the "/vary" command to the corresponding image in the grid, creating variations of that image.</li>
            <li><code>🍿</code> - GIF: This emoji transforms the corresponding image into a GIF.</li>
          </ul>
          <h2>Audio Generation Commands</h2>
          <ul>
            <li><code>/speak</code> - Generates an AI audio file from the text entered.</li>
            <li><code>/music</code> - Generates AI music based on the style you specify.</li>
          </ul>
          <h2>Audio Generation Flags</h2>
          <ul>
            <li><code>-tyson</code> - Generates the audio to sound like Mike Tyson.</li>
            <li><code>-biden</code> - Generates the audio to sound like Joe Biden.</li>
            <li><code>-trump</code> - Generates the audio to sound like Donald Trump.</li>
            <li><code>-elon</code> - Generates the audio to sound like Elon Musk.</li>
            <li><code>-bezos</code> - Generates the audio to sound like Jeff Bezos.</li>
            <li><code>-valley</code> - Generates the audio to sound like a Valley Girl.</li>
            <li><code>-bruce</code> - Generates the audio in a deep male voice.</li>
            <li><code>-wayne</code> - Generates the audio in a deep male voice.</li>
            <li><code>-myriam</code> - Generates the audio in a young female voice.</li>
            <li><code>-nigel</code> - Generates the audio with an English accent in a male voice.</li>
            <li><code>-apu</code> - Generates the audio with an Indian accent in a male voice.</li>
            <li><code>-aaron</code> - Generates the audio in a male voice.</li>
            <li><code>-tally</code> - Generates the audio in a female voice.</li>
            <li><code>-peter</code> - Generates the audio in a male voice.</li>
            <li><code>-rachel</code> - Generates the audio in a female voice.</li>
            <li><code>-karen</code> - Generates the audio in a female voice.</li>
          </ul>
          <h2>Remember to use these commands responsibly and in accordance with the guidelines and rules of the server. If you have any further questions or need assistance, please reach out to the server moderators or administrators.</h2>
        </div>
      );
      
}

