import React, { useMemo, useState, useEffect } from 'react';
//import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateEntryAsync } from '../../Component/Redux/changelogSlice.js';
import { createModulesConfig } from './quillSetup';
import 'react-quill/dist/quill.snow.css';
import styles from './editChangelogEntryPage.module.css';
import SharedEditor from './sharedEditor';
import { getAuthorizationLevel } from '../../Utils/api.js';
import backButtonImage from '../../assets/backarrow.png';

export function EditChangelogEntryPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const entry = useSelector(state => state.changelog.entries.find(e => e.id === parseInt(id)));
  const [changeTitle, setChangeTitle] = useState(entry ? entry.changeTitle : "");
  const [editorContent, setEditorContent] = useState(entry ? entry.changeText : "");
  const [authorizationLevel, setAuthorizationLevel] = React.useState(null);
  const [backgroundColor, setBackgroundColorState] = useState(entry ? entry.backgroundColor : '#FFFFFF'); // Get background color from the entry or default to white

  useEffect(() => {
      const customButton = document.createElement('button');
      customButton.className = 'ql-set-bg-color ql-bold';
      customButton.onclick = () => {
          setBackgroundColorState(backgroundColor);
      }

      const toolbarContainer = document.querySelector('.ql-toolbar');
      if (toolbarContainer) { 
          toolbarContainer.appendChild(customButton);
      }
  
      const fetchData = async () => {
          const level = await getAuthorizationLevel();
          setAuthorizationLevel(level);
      }
      fetchData();
  }, [backgroundColor]);



  useEffect(() => {
    if (entry) {
      setChangeTitle(entry.changeTitle || "");
      setEditorContent(entry.changeText || "");
      setBackgroundColorState(entry.backgroundColor || "#FFFFFF");
    } else {
      setChangeTitle("");
      setEditorContent("");
      setBackgroundColorState("#FFFFFF");
    }

    const quillEditorElement = document.querySelector('.ql-editor');
    if (quillEditorElement) {
        quillEditorElement.style.setProperty('background-color', backgroundColor, 'important');
    }
  }, [entry]);

  const handleSave = () => {
    dispatch(updateEntryAsync({ id: parseInt(id), changeTitle, changeText: editorContent, backgroundColor }))
      .then(() => {
        navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`);
      })
      .catch((error) => {
        console.error("Error updating entry:", error);
      });
  }


  const quillModules = useMemo(() => createModulesConfig(setBackgroundColorState), [setBackgroundColorState]);

  return (
    <div className={styles.container}>
        <h2 className={styles.title}>Edit Changelog Entry</h2>
        <div className={styles.inputGroup}>
            <label>Title:</label>
            <input className={styles.input} value={changeTitle} onChange={e => setChangeTitle(e.target.value)} />
        </div>
        <SharedEditor content={editorContent} setContent={setEditorContent} modules={quillModules} />
        {authorizationLevel === "admin" && (
        <button className={styles.button} onClick={handleSave}>Save</button>)}
        
        <div className={styles.buttoncontainer}>
          <button className={styles.backButton} onClick={() => window.location.href = `https://www.CraftFul.ai/changelog?${process.env.REACT_APP_CURRENT_VERSION}`}>
            <img src={backButtonImage} alt="Back" />
          </button>
        </div>
    </div>
  );
}
