import React from 'react';
import image_vid1 from '../assets/image_vid1.png';
import image_vid2 from '../assets/image_vid2.png';
import image_vid3 from '../assets/image_vid3.png';
import image_vid4 from '../assets/image_vid4.png';
import image_vid5 from '../assets/image_vid5.png';
import image_vid_init from '../assets/image_vid_init.png';
import video from '../assets/video.mp4';
import batch1 from '../assets/batch1.mp4';
import batch2 from '../assets/batch2.mp4';
import rawbatch1 from '../assets/rawbatch1.mp4';
import rawbatch2 from '../assets/rawbatch2.mp4';
//import movie from '../assets/movie.mp4';
import './VideoCommandDocumentation.css';

export function VideoCommandDocumentation() {
    return (
        <div className="title">
          <center>
            <h1>How To Use the Different Video Commands With our Discord Bot</h1>
          </center>
          <div className="container">
            <h2>Step 1: Choose the Right Movie Generation Channel</h2>
            <p>First, you'll need to navigate to one of the following movie-generation channels on our Discord server:</p>
            <div className="tip-box">
              <p>🎬・video-gen-1<br />🎬・video-gen-2<br />🎬・video-gen-3<br />🎬・video-gen-4<br />🎬・horror-video-gen - <span style={{ color: '#FE78FD' }}>this one is for Gore and Horror</span></p>
            </div>
            <br /><br />
            <div>
              <h2>Step 2: Enter Your Video Command</h2>
              <p>Once you've selected the channel that fits your desired theme, you can create your video generation by using one of the available commands followed by your prompt.</p>
            </div>
            <div className="tip-box">
              <ul>
                <li><span style={{ color: '#FE78FD' }}>/video</span> - Creates a 3-second AI video from text. This command allows you to generate videos based on the text you enter, bringing your ideas to life.</li>
                <li><span style={{ color: '#FE78FD' }}>/batchvideo</span> - Generates two similar but uniquely different video clips in a single command. This command is ideal for creating variations of a concept in one go, allowing for a range of options to choose from.</li>
                <li><span style={{ color: '#FE78FD' }}>/rawbatchvideo</span> - Will get you the raw access to the model, which will result in way more movement at the expense of visual fidelity.</li>
                <li><span style={{ color: '#FE78FD' }}>/movie</span> - Creates a 12-second AI Movie consisting of 4 scenes and a music track based on the text you enter.</li>
              </ul>
            </div>
            <br /><br />
            <div>
              <h2>Here are some examples of how the commands work:</h2>
            </div>
            <div className="tip-box">
              <p><span style={{ color: '#FE78FD' }}>Example:</span> /video prompt: A breathtaking landscape with a mountain and a river running rapidly through it.</p>
              <p><img src={image_vid1} alt="example" /></p>
            </div>
            <div className="tip-box">
              <p><span style={{ color: '#FE78FD' }}>Example:</span> /batchvideo prompt: A breathtaking landscape with a mountain and a river running rapidly through it.</p>
              <p><img src={image_vid2} alt="example" /></p>
            </div>
            <div className="tip-box">
              <p><span style={{ color: '#FE78FD' }}>Example:</span> /rawbatchvideo prompt: A breathtaking landscape with a mountain and a river running rapidly through it.</p>
              <p><img src={image_vid3} alt="example" /></p>
            </div>
            <div className="tip-box">
              <p><span style={{ color: '#FE78FD' }}>Example:</span> /movie prompt: A breathtaking landscape with a mountain and a river running rapidly through it.</p>
              <p><img src={image_vid4} alt="example" /></p>
            </div>
            <br /><br />
            <div>
              <h2>Advanced Tips</h2>
              <p>Here are some advanced tips to enhance your experience with the video commands:</p>
            </div>
            <div className="tip-box">
              <ul>
                <li>When using /video you can use your own image as the initial subject. It should keep most of the image and produce a video based on your text.</li>
                <center><img src={image_vid_init} alt="advanced tip" /></center>
                <br /><br />
                <li>The more movement specified in your text, the better your result. This applies to all text-to-video commands. For example, the prompt "a breathtaking landscape with a mountain and a river running rapidly through it" will give you better results than "a breathtaking landscape with a mountain and a river."</li>
              </ul>
            </div>
            <br /><br />
            <div>
              <h2>Step 3: Wait for the Response</h2>
              <p>Once you enter your command and hit enter, the bot will return a message that says "Your request is in queue! We will notify you when it is ready."</p>
            </div>
            <div className="tip-box">
              <p><img src={image_vid5} alt="waiting queue" /></p>
            </div>
            <br /><br />
            <div>
              <h2>Step 4: Receive Your Generated Video</h2>
              <p>Your prompt will go into the queue in the order it was received. When the bot finishes creating your text-to-video, it will post it in the room you ran the command and ping you with a notification letting you know it's ready.</p>
              <br /><br />
              <h2>Here are some examples of how the results look:</h2>
            </div>
            <div className="tip-box">
              <p><h3>Video Results:</h3></p>
              <center>
                <p>
                  <video width="640" height="360" controls>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </p>
                <p><h3>BatchVideo Results:</h3></p>
                <p>
                  <video width="320" height="180" controls>
                    <source src={batch1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <video width="320" height="180" controls>
                    <source src={batch2} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </p>
                <p><h3>RawBatchVideo Results:</h3></p>
                <p>
                  <video width="320" height="180" controls>
                    <source src={rawbatch1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <video width="320" height="180" controls>
                    <source src={rawbatch2} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </p>
              </center>
            </div>
          </div>
        </div>
      );
};