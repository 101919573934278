import React from 'react';
import speakImage from '../assets/speak.png'; // Replace with the actual path to the image
import musicImage from '../assets/music.png'; // Replace with the actual path to the image
import soundImage from '../assets/sound.png'; // Replace with the actual path to the image
import speakQueueImage from '../assets/speakqueue.png'; // Replace with the actual path to the image
import speakVideo from '../assets/speak.mp4'; // Replace with the actual path to the video
import musicVideo from '../assets/music.mp4'; // Replace with the actual path to the video
import soundVideo from '../assets/sound.mp4'; // Replace with the actual path to the video

import './AudioCommandDocumentation.css';

export function AudioCommandDocumentation() {
    return (
        <div className="container">
          <div className="title">
            <h1>How to Use Audio Commands with Our Discord Bot</h1>
          </div>
          <div>
            <h2>Step 1: Choose the Right Audio Generation Channel</h2>
            <p>First, you'll need to navigate to one of the following audio-generation channels on our Discord server:</p>
            <div className="tip-box">
              <p>🎶・music-generation<br />🗣・speech-generation<br />📣・sound-effects</p>
            </div>
          </div>
          <div>
            <h2>Audio Generation Commands</h2>
            <div className="tip-box">
              <ul>
                <li><b style={{ color: '#FE78FD' }}>/speak</b> - Generates an AI audio file from the text entered. This command allows you to convert text into realistic-sounding speech using AI.</li>
                <li><b style={{ color: '#FE78FD' }}>/music</b> - Generates AI music based on the style you specify. This command provides you with AI-generated music that matches your desired style.</li>
                <li><b style={{ color: '#FE78FD' }}>/sound</b> - Generates an AI sound effect based on your text input.</li>
              </ul>
            </div>
          </div>
          <div>
            <h2>Audio Generation Flags</h2>
            <p>Use these flags with the /speak command to generate audio in the voice of various personalities:</p>
            <div className="tip-box">
              <ul>
              <li>-tyson - Generates the audio to sound like Mike Tyson.</li>
                    <li>-biden - Generates the audio to sound like Joe Biden.</li>
                    <li>-trump - Generates the audio to sound like Donald Trump.</li>
                    <li>-elon - Generates the audio to sound like Elon Musk.</li>
                    <li>-bezos - Generates the audio to sound like Jeff Bezos.</li>
                    <li>-valley - Generates the audio to sound like a Valley Girl.</li>
                    <li>-bruce - Generates the audio in a deep male voice.</li>
                    <li>-myriam - Generates the audio in a young female voice.</li>
                    <li>-nigel - Generates the audio with an English accent in a male voice.</li>
                    <li>-apu - Generates the audio with an Indian accent in a male voice.</li>
                    <li>-aaron - Generates the audio in a male voice.</li>
                    <li>-tally - Generates the audio in a female voice.</li>
                    <li>-ixel - Generates the audio in an English male voice.</li>
                    <li>-victoria - Generates the audio in a English female voice.</li>
                    <li>-freeman - Generates the audio to sound like Morgan Freeman.</li>
                    <li>-rdj - Generates the audio to sound like Robert Downey Jr.</li>
                    <li>-obama - Generates the audio to sound like Barack Obama.</li>
                    <li>-sda - Generates the audio to sound like Sir David Attenborough.</li>
                    <li>-radio - Generates the audio to sound like a radio voice guy.</li>
              </ul>
            </div>
          </div>
          <div>
            <h2>Example Usage:</h2>
            <div className="tip-box">
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /speak Welcome to our community! -elon [Generates speech in Elon Musk's voice]</p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /music 175bpm, a minor key, minimal drum and bass, wobble bassline, cinematic, high quality</p>
              <p><b style={{ color: '#FE78FD' }}>Example:</b> /sound Thunderstorm approaching [Generates a sound effect of a thunderstorm]</p>
            </div>
          </div>
          <div>
            <h2>Step 2: Enter Your Command</h2>
            <p>Once you've selected the channel that fits your desired theme, you can create your audio generation by using the appropriate command and flags.</p>
          </div>
          <div className="tip-box">
            <img src={speakImage} alt="speak command"/>
            <br /><br />
            <img src={musicImage} alt="music command"/>
            <br /><br />
            <img src={soundImage} alt="sound command"/>
          </div>
          <div>
            <h2>Step 3: Wait for the Response</h2>
            <p>Once you enter your command and hit enter, the bot will return a message that says "Your request is in queue! We will notify you when it is ready."</p>
          </div>
          <div className="tip-box">
            <img src={speakQueueImage} alt="speak queue"/>
          </div>
          <div>
            <h2>Step 4: Receive Your Generated Audio</h2>
            <p>Your prompt will go into the queue in the order it was received. When the bot finishes creating your audio, it will post it in the room you ran the command and ping you with a notification letting you know it's ready.</p>
            <h2>Here are some examples of how the results look:</h2>
          </div>
          <div className="tip-box">
            <h3>Speak Results:</h3>
            <video width="640" controls>
              <source src={speakVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h3>Music Results:</h3>
            <video width="640" controls>
              <source src={musicVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <h3>Sound Results:</h3>
            <video width="640" controls>
              <source src={soundVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <h3>Conclusion</h3>
            <p>The audio commands provide a versatile way to create voiceovers, music, and sound effects tailored to your needs. With a wide range of voice options and the ability to describe your desired sound, you can bring your auditory vision to life. Explore and enjoy these features within our Discord community, and unleash your creativity with sound! 🎤🎶✨</p>
          </div>
        </div>
      );
};