  import React, { useEffect, useState, useContext } from 'react';
  import { useLocation, useNavigate } from 'react-router-dom';
  import SubscriptionContext from '../../Component/SubscriptionContext';
  import CreditPackageContext from '../../Component/CreditPackageContext';
  import {GetVersion, getCustomerGiftCode } from '../../Utils/api.js';
  import axios from 'axios';

  import './index.css';
  import styles from './Success.module.css';

  const images = [
    "/thankyou1.png",
    "/thankyou2.png",   
    "/thankyou3.png",
    "/thankyou4.png",
  ];

  const randomImage = images[Math.floor(Math.random() * images.length)];



  export function SuccessPage() {
  const [sessionDetails, setSessionDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [giftCode, setGiftCode] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    
    console.log("UseEffect in SuccessPage with sessionId: ", sessionId);
  
    const fetchSessionDetails = async (sessionId) => {
      let response;
      try {
        response = await axios.get(`https://CraftFul.ai/api/payment/session/${sessionId}`);
        setSessionDetails(response.data);
      } catch (error) {
        console.error('Error fetching session details:', error);
        setError('Failed to fetch session details');
      } 

      try {
        const fetchedGiftCode = await getCustomerGiftCode(response.data.customer);
        setGiftCode(fetchedGiftCode.giftcode);
        console.log("Gift code is: ", fetchedGiftCode.giftcode);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching gift code:', error);
        //setError('Failed to fetch gift code');
        setGiftCode(null);
        setLoading(false);
      }
    };
  
    if (sessionId) {
      fetchSessionDetails(sessionId);
    } else {
      setError('No session ID found in URL');
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log("Session details: ", sessionDetails);
  }, [sessionDetails]);


  if (loading) {
    return (
      <div className={styles['success-container']}>
        <div className={styles.loader}></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className={`${styles['success-container']} ${styles.error}`}>
        {error}
      </div>
    );
  }

 
  return (
    <div className={styles['success-container']}>
      <h1 className={styles.h1}>Thank You for Your Purchase!</h1>
      <p className={styles.p}>Your transaction was successful. Here are the details of your purchase:</p>
      <div className={styles.details}>
        <div><strong className={styles.strong}>Your Purchase:</strong> {sessionDetails.metadata.gift?" A gift of: " +sessionDetails.metadata.tierType:sessionDetails.metadata.tierType}</div>
        <div><strong className={styles.strong}>Transaction ID:</strong> {sessionDetails.id}</div>
        <div><strong className={styles.strong}>Amount Paid:</strong> ${(sessionDetails.amount_total / 100).toFixed(2)}</div>
        <div><strong className={styles.strong}>Email:</strong> {sessionDetails.customer_details?.email || 'N/A'}</div>
        {giftCode && (
        <div><strong className={styles.strong}>GIFT CODE:</strong> {giftCode || 'Loading...'}</div>
      )}
      </div>
      <button className={styles.button} onClick={() => window.print()}>Print Receipt</button>
      <button className={styles.button} onClick={() => window.location.href = '/dashboard'}>Dashboard</button>
    </div>
  );
}

  
  
  

  export function LandingPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const [selectedTierIndex] = useState(location.state.selectedTierIndex);
    const [paymentIntent] = useState(location.state.paymentIntent);
    const [whatwasbought] = useState(location.state.whatwasbought); // [0] = tier, [1] = price, [2] = message 
    const [packageType, setPackageType] = useState(null);

    const subscriptionTiers = useContext(SubscriptionContext);
    const creditPackages = useContext(CreditPackageContext);

    const goToDashboard = () => {
      navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`); // If the user is logged in, redirect them to the dashboard page
    };

    useEffect(() => {
      console.log("Version is: ",GetVersion());

      if (!paymentIntent) {
        navigate(`/dashboard?${process.env.REACT_APP_CURRENT_VERSION}`);
      } else {
        // Set the package type
        setPackageType(whatwasbought.type);
      }
    }, [location.state, navigate, paymentIntent]);

    return (
      <div className="backgroundImage" style={{ backgroundImage: `url(${randomImage})` }}>
          <div className="content-wrapper" style={{ marginTop: '30px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          
              <div className="confirmation-card" style={{ width: '50%', textAlign: 'center', border: '1px solid black', borderRadius: '10px', padding: '20px', backgroundColor: 'white' }}>
                  <h1>Thank you for your purchase!</h1>
                  <custom-h2>Confirmation #:{paymentIntent.id}</custom-h2>
                  <custom-h3>You purchased the "{whatwasbought.name}" package</custom-h3>
                  <custom-h3>Price: {(whatwasbought.discounted || whatwasbought.discountName) ? <span style={{ color: 'green' }}>Discounted </span> : ''}${whatwasbought.price}</custom-h3>
                  <custom-h4>Details: </custom-h4>
                  <div dangerouslySetInnerHTML={{ __html: whatwasbought.details }}></div>
              </div>
              <button 
                  id="goToDashboardButton" 
                  style={{fontSize: "20px", marginTop: '20px'}} 
                  onClick={goToDashboard}>
                  Back to Dashboard
              </button>
          </div>
          <p> version is: {GetVersion()}</p>
      </div>
  );
  }


