import React, { useState } from 'react';
import styles from './GiftCodeModal.module.css'; // Create and import the CSS module similar to emailUpdateModal.module.css

export const GiftCodeModal = ({ onRedeem, onClose }) => {
    const [code, setCode] = useState('');

    const handleRedeem = (e) => {
        e.preventDefault();
        onRedeem(code);
    };

    return (
        <div className={styles.backdrop}>
            <div className={styles.modal}>
                <h1 style={{ color: 'black', backgroundColor: 'transparent', textAlign: 'center' }}>Enter Gift Code to Redeem</h1>
                <form onSubmit={handleRedeem} style={{ textAlign: 'center' }}>
                    <div className={styles.content}>
                        <label htmlFor="code" style={{ marginRight: '10px' }}>Gift Code:</label>
                        <input
                            type="text"
                            id="code"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                            required
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <div className={styles.actions}>
                        <button type="submit" className={styles.submit}>Redeem</button>
                        <button type="button" onClick={onClose} className={styles.close}>Close</button>
                    </div>
                </form>
            </div>
        </div>
    );
};
