import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GetVersion, getUserDetails, getCustomerGiftCode,getCustomerGiftCodeList } from '../../Utils/api.js';
import axios from 'axios';
import { MrInvoice } from './InvoiceModal';

import styles from './paymentHistory.module.css';

export function PaymentHistoryPage() {
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [giftCodes, setGiftCodes] = useState([]); // State to store gift codes
    const [codesValidity, setCodesValidity] = useState({}); // Stores validity of each code

    const navigate = useNavigate();

    const handleInvoiceClick = (payment) => {
        setSelectedInvoice(payment);
    };

    const handleCloseModal = () => {
        setSelectedInvoice(null);
    };
    useEffect(() => {
        console.log("Version is: ", GetVersion());

            
        const fetchUserPaymentHistory = async () => {
            try {
                /*
                const setResponse = await axios.get(`/api/auth/set-test`, { withCredentials: true });
                console.log(setResponse.data); // This will log the response from the set-test route
              
                const getResponse = await axios.get(`/api/auth/get-test`, { withCredentials: true });
                console.log(getResponse.data); // This will log the response from the get-test route
*/
                const { data } = await getUserDetails();
                setUser(data);
    
                if (data.stripeid) {
                    try {
                        // Fetch payment history
                        const response = await axios.get(`/api/payment/payment-history/` + data.stripeid);
                        setPaymentHistory(response.data);
    
                        // Fetch gift codes
                        const giftCodeData = await getCustomerGiftCodeList(data.stripeid);
                        if (giftCodeData) {
                            console.log("Gift code data: '", giftCodeData, "'");
                            const trimmedGiftCodeData = giftCodeData.trim(); // Trim the whitespace
                            const codesWithDates = trimmedGiftCodeData.split('|').map(entry => {
                                const colonIndex = entry.indexOf(':');
                                const code = entry.substring(0, colonIndex).trim();
                                const dateString = colonIndex !== -1 ? entry.substring(colonIndex + 1).trim() : null;
                                const timestamp = dateString ? Date.parse(dateString) : NaN;
                                const date = !isNaN(timestamp) ? new Date(timestamp).toLocaleDateString() : 'Invalid Date';
                                return { code, date }; // this returns an object with the code and date
                            });
    
                            // Verify the validity of each code
                            const validity = {};
                            for (const entry of codesWithDates) {
                                try {
                                    const response = await axios.post('/api/payment/verify_coupon', { couponid: entry.code });
                                    validity[entry.code] = response.data; // Assuming response data indicates validity
                                } catch (error) {
                                    validity[entry.code] = false; // If there's an error, consider it invalid
                                }
                            }
                            setCodesValidity(validity);
                            setGiftCodes(codesWithDates);
                        }
                    } catch (error) {
                        console.log("Error getting user details on Payment History Page: " + error);
                        setError(error);
                    }
                } else {
                    navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
                }
            } catch (err) {
                console.log("Error getting user details on Payment History Page: " + err);
                navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
            } finally {
                setLoading(false);
            }
        };
    
        fetchUserPaymentHistory();
    }, [navigate]);

    if (loading) return <div className="fullscreen-spinner"><div className="spinner" id="spinner"></div></div>;
    if (error) return <div>Error loading payment history.</div>;

    return (
        <div className={styles.paymentHistory}>
            <h2>Gift Codes</h2>
            <div>
                {giftCodes.map((entry, index) => (
                    <div key={index}>
                        <span style={{ textDecoration: codesValidity[entry.code] ? 'none' : 'line-through' }}>
                            Code #{index + 1}: {entry.code}
                        </span>
                        <span> - {entry.date}</span>
                        {!codesValidity[entry.code] && <span> No longer valid</span>}
                    </div>
                ))}
            </div>

            <h2>Payment History</h2>
            <div className={styles.tableResponsive}>
                <table className={styles.paymentTable}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Type</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentHistory.map((payment) => (
                            <tr key={payment.id} onClick={() => handleInvoiceClick(payment)}>
                                <td>{new Date(payment.date * 1000).toLocaleDateString()}</td>
                                <td>{payment.invoice_pdf && payment.amount != null ? '--' : `$${(payment.amount / 100).toFixed(2)}`}</td>
                                <td>{payment.status}</td>
                                <td>{payment.type}</td>
                                <td>{payment.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {selectedInvoice && (
                <MrInvoice invoice={selectedInvoice} onClose={handleCloseModal} />
            )}
        </div>
    );
};
