import React, { useState } from 'react';
import styles from './emailUpdateModal.module.css'; // Import the CSS module

export const EmailUpdateModal = ({ onSubmit, onClose }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(email);
    };

    return (
        <div className={styles.backdrop}>
            <div className={styles.modal}>
                <h1 style={{ color: 'black', backgroundColor: 'transparent', textAlign: 'center' }}>Please Update your Email Address</h1>
                <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
                    <div className={styles.content}>
                        <label htmlFor="email" style={{ marginRight: '10px' }}>New Email:</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            style={{ textAlign: 'center' }}
                        />
                    </div>
                    <div className={styles.actions}>
                        <button type="submit" className={styles.submit}>Update Email</button>
                        <button type="button" onClick={onClose} className={styles.close}>Close</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

