import React, { useState, useEffect } from 'react';
import { deleteUser, GetVersion, getAdditionalUserDetails, logoutUser, 
         updateSubscriptionStatus, setUserAuthLevel, updateFJCustomer,
         testTheStripePost, redeemGiftCode } from '../../Utils/api.js';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';

export function CommandDashboardPage() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState(0);
    const [subscriptionStatusDisplay, setSubscriptionStatusDisplay] = useState('Fetching subscription status...');
    const [loadedUser, setLoadedUser] = useState('Fetching user details...');
    const [creditsDisplayed, setCreditsDisplayed] = useState('Fetching credits...');
    const [hyperCreditsDisplayed, setHyperCreditsDisplayed] = useState('Fetching hypercredits...');
    const [DiscordId, setDiscordID] = useState('');
    const [Password, setPassword] = useState('');
    const [testGiftCode, settestGiftCode] = useState('');
    const [newDiscordName, setNewDiscordName] = useState('');
    const [newCredits, setNewCredits] = useState('');
    const [newHyperCredits, setNewHyperCredits] = useState('');
    const [newSubscriptionStatus, setNewSubscriptionStatus] = useState('');

    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const processCreditsDisplay = (credits) => {
        if (credits > 14999)
            return "Unlimited";
        else
            return credits;
    };

    const doThisGuy = async () => {
        const cidToTest = 'cus_QsI2Dtc3WAHUIR';
        console.log("Attempting to test stripe post with good_times1 customer id: ", cidToTest);
        try{
        await testTheStripePost(cidToTest); // 'cus_QsI2Dtc3WAHUIR' is for good_times1 and jeromes is 'cus_P7JOOocItVMT5l'
        console.log("Success");
        } catch (err) {
            console.log(err);
        }
    };

    const inputTheCode = (e) => {
        settestGiftCode(e.target.value);

        console.log("testGiftCode: ", e.target.value);
    }

    // This function calls the following other function to start the process of redeeming a gift code:
    //  async function redeemGiftCode( giftCode, email, userId, stripeId
    const testRedeemCode = async () => {
        console.log("Attempting to redeem gift code: ", testGiftCode);
        try {
          let { data } = await redeemGiftCode(testGiftCode, user.email, user.user_id, user.stripeid);
          console.log(data);
      
          // Redirect the user to the Stripe checkout
          window.location.href = data.url;
        } catch (err) {
          console.log("Error redeeming gift code: ", err);
          return;
        }
      
        console.log("Test Redeem Code");
      };



    const getUserInfo = async () => {
        try {
            let { data } = await getAdditionalUserDetails(DiscordId, Password);
            console.log("Loaded User: ", data);
            setUser(data);
            setLoadedUser("User Loaded");
            
        } catch (err) {
            console.log("Error getting user info: ", err);
            console.log(err);
            return;
        }
    };

    const deleteThisUser = async () => {
        console.log("Attempting to delete user: ", DiscordId);
        try {
            let { data } = await deleteUser(DiscordId, Password);
            console.log(data);
        } catch (err) {
            console.log("Error deleting user: ", err);
            console.log(err);
            return;
        }
        console.log("User deleted successfully");
    };

    const setUserAuth = async () => {
        try {
            let { response } = await setUserAuthLevel(DiscordId, "admin", Password);
            console.log("Success: ", response);
        } catch (err) {
            console.log(err);
            return;
        }
    };

    const updateField = async (field, value) => {
        console.log("in updateField with user: ", user);
        console.log("field: ", field, " value: ", value);       
        if (user && user.stripeid && user.user_id) {
            console.log("There is a user and stripeId");
            try {
                await updateFJCustomer(user.stripeid, user.user_id, { [field]: value });
                console.log(`${field} updated to ${value}`);
                // And update the display with getUserInfo
                getUserInfo();
            } catch (error) {
                console.error(`Error updating ${field}:`, error);
            }
        } else {
            console.log("No user/stripeid");
        }
    };

    useEffect(() => {
        console.log("Version is: ", GetVersion());

        if (user) {
            const fetchSubscriptionStatus = async () => {
                try {
                    let response = await updateSubscriptionStatus(user.user_id);
                    let updatedUser = response.data;

                    console.log("Updated user: ", updatedUser);

                    setSubscriptionStatusDisplay(updatedUser.subscription_status);
                    setCreditsDisplayed(processCreditsDisplay(updatedUser.credits));
                    setHyperCreditsDisplayed(updatedUser.hypercredits);
                } catch (err) {
                    console.log(err);
                }
            };
            fetchSubscriptionStatus();
        }
    }, [user, update]);

    useEffect(() => {
        if (query.get('refresh') === 'true') {
            setUpdate(update + 1);
        }
    }, [query.get('refresh')]);

    const logoutButton = async () => {
        try {
            await logoutUser();
            navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
        } catch (err) {
            console.log(err);
        }
    };

    
    const navigateButton = (path) => {
        navigate(path);        
    };


  
    // Render logic
    if (!loading) {
        return (
            <div className="dashboard-page p-8">
                <button id="paymentHistoryButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/subscription?${process.env.REACT_APP_CURRENT_VERSION}&purchaseType=Gift`)}>Gift A Subscription</button>
                <label htmlFor="DiscordId">DiscordId</label>
                <input id="DiscordId" type="text" value={DiscordId} onChange={(e) => setDiscordID(e.target.value)} />
                <label htmlFor="Password">Password</label>
                <input id="password" type="password" value={Password} onChange={(e) => setPassword(e.target.value)} />
    
                <div>
                    <label htmlFor="testGiftRedeem">Enter Gift Code Below</label>
                    <input id="testGiftRedeem" type="text" value={testGiftCode} onChange={(e) => inputTheCode(e)} />
                    <button onClick={() => testRedeemCode()}>Test Redeem Code</button>
                </div>

                <button onClick={doThisGuy}>TestStripePost</button>
                <button onClick={getUserInfo}>Get User Info</button>
                
                <div>
                    <label htmlFor="newDiscordName">New Discord Name</label>
                    <input id="newDiscordName" type="text" value={newDiscordName} onChange={(e) => setNewDiscordName(e.target.value)} />
                    <button onClick={() => updateField('discordName', newDiscordName)}>Update Discord Name</button>
                    <div>
                        Current Discord Name: {user ? user.discordname : 'N/A'}
                    </div>
                </div>
    
                <div>
                    <label htmlFor="newCredits">New Credits</label>
                    <input id="newCredits" type="number" value={newCredits} onChange={(e) => setNewCredits(e.target.value)} />
                    <button onClick={() => updateField('credits', newCredits)}>Update Credits</button>
                    <div>
                        Current Credits: {creditsDisplayed}
                    </div>
                </div>

                <div>
                    <label htmlFor="newHyperCredits">New Credits</label>
                    <input id="newHyperCredits" type="number" value={newHyperCredits} onChange={(e) => setNewHyperCredits(e.target.value)} />
                    <button onClick={() => updateField('hypercredits', newHyperCredits)}>Update Credits</button>
                    <div>
                        Current Credits: {hyperCreditsDisplayed}
                    </div>
                </div>
    
                <div>
                    <label htmlFor="newSubscriptionStatus">New Subscription Status</label>
                    <input id="newSubscriptionStatus" type="text" value={newSubscriptionStatus} onChange={(e) => setNewSubscriptionStatus(e.target.value)} />
                    <button onClick={() => updateField('subscription_status', newSubscriptionStatus)}>Update Subscription Status</button>
                    <div>
                        Current Subscription Status: {subscriptionStatusDisplay}
                    </div>
                </div>
    
                <button onClick={logoutButton}>Logout</button>
                <button onClick={setUserAuth}>Give User Admin Access</button>
                ...
                <button onClick={deleteThisUser}>Delete User</button>

            </div>
        );
    } else {
        return (
            <div className="fullscreen-spinner">
                <div className="spinner" id="spinner"></div>
            </div>
        );
    }
}
