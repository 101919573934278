import React, { useEffect, useState } from 'react';
import logo from './craftful-header-nobg.png';
import { GetVersion, getUserDetails } from '../../Utils/api.js';
import ErrorComponent from '../../Component/ErrorComponent.jsx';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import './index.css';
import styles from './index.module.css';

export function MenuPage() {
    console.log("Version is: ", GetVersion());
    const [hasError, setHasError] = useState(false);
    const navigate = useNavigate();

    // This is the user object that will be returned from the Discord OAuth2 login page
    const [user, setUser] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        document.title = 'FLUX Development with Craftful.ai - Your Complete AI Media Generation Platform!';
        const metaDescription = document.querySelector("meta[name='description']");
        if (metaDescription) {
            metaDescription.setAttribute('content', 'Join Craftful.ai, the ultimate platform for AI-driven media generation, now with FLUX development capabilities.');
        } else {
            const meta = document.createElement('meta');
            meta.name = 'description';
            meta.content = 'Join Craftful.ai, the ultimate platform for AI-driven media generation, now with FLUX development capabilities.';
            document.head.appendChild(meta);
        }
    }, []);

    const goToDiscord = () => {
        window.location.href = "https://discord.gg/kukNqTv7jA";
    };

    const loginViaDiscord = () => {
        // 'localhost:3001/auth' is our Express server's address for the Discord OAuth2 login page
        window.location.href = "http://www.craftful.ai/api/auth/discord";
    };

    // This is the code that will run when the component is first rendered
    React.useEffect(() => {
        getUserDetails().then(({ data }) => {
            console.log(data);
            setUser(data);
            setLoading(false);
            if (data) {
                navigate(`/dashboard?${process.env.REACT_APP_CURRENT_VERSION}`); // If the user is logged in, redirect them to the dashboard page
            }
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            //setHasError(true);
        });
    }, [navigate]);

    /* if (hasError) {
        return <ErrorComponent />;
      }*/

    return !loading && (
        <div className="App">
             <Helmet>
                <meta property="og:title" content="FLUX Development with Craftful.ai" />
                <meta property="og:description" content="Join Craftful.ai, the ultimate platform for AI-driven media generation, now with FLUX development capabilities." />
                <meta property="og:url" content="https://www.craftful.ai" />
                <meta property="og:image" content="https://www.craftful.ai/cf-logo(192).png" />
                <meta property="og:type" content="website" />
            </Helmet>
            <header className="App-header">
                <img src={logo} className={styles.logo} alt="logo" />
                <p>
                    Welcome to CraftFul, your complete suite of AI Creation tools at your fingertips! With our tools, you can create amazing videos and movies from simple prompts! Images, Music, and Speech Generation Too! Now with FLUX development support, you can supercharge your projects like never before! Join us today on Discord!
                </p>
                <button
                    id="discordButton"
                    className="rounded-button"
                    style={{ fontSize: "20px" }}
                    onClick={goToDiscord}>
                    Join us on Discord!
                </button>
                <p id="getstarted"> In order to get started, you first need to login with your Discord account</p>
                <button
                    id="loginButton"
                    className="rounded-button"
                    style={{ fontSize: "20px", marginTop: "20px" }}
                    onClick={loginViaDiscord}>
                    Login Via Discord
                </button>
                <div>
                    <button
                        id="Subscribe"
                        className="rounded-button"
                        style={{ fontSize: "20px", marginTop: "20px" }}
                        onClick={loginViaDiscord}>
                        Subscribe
                    </button>
                    <button
                        id="BuyCredits"
                        className="rounded-button"
                        style={{ fontSize: "20px", marginTop: "20px" }}
                        onClick={loginViaDiscord}>
                        Buy Credits
                    </button>
                </div>
                <div>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/documentation" className="custom-link"> A list of all the commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/loradocumentation" className="custom-link">
                            Lora commands <span style={{ color: 'yellow' }}>NEW!!!</span>
                        </Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/makecommanddocumentation" className="custom-link">Image generation commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/videocommanddocumentation" className="custom-link">Video generation commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4 mb-1">
                        <Link to="/audiocommanddocumentation" className="custom-link">Audio generation commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4 mb-1">
                        <Link to="/lipsyncdocumentation" className="custom-link">Lipsync generation commands</Link>
                    </p>
                </div>
                <div className={styles.changelogcontainer}>
                    <button className={styles.changelogbutton} onClick={() => window.location.href = `https://www.craftful.ai/changelog?${process.env.REACT_APP_CURRENT_VERSION}`}>
                        Change Log
                    </button>
                </div>
            </header>
            <footer style={{ textAlign: 'center', marginTop: '2rem' }}>
                Have Questions Or For More Information Contact -
                <a href="mailto:Info@CraftFul.ai">
                    Info@CraftFul.ai
                </a>
            </footer>
            <p> version is: {GetVersion()}</p>
        </div>
    );
}
