import React from 'react';
import './App.css';
import {Routes, Route } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useLocation} from 'react-router-dom';
import { SubscriptionProvider } from './Component/SubscriptionProvider'; 
import { CreditPackageProvider } from './Component/CreditPackageProvider';
import {TermsOfService} from './Component/TermsOfService.jsx';
import {PrivacyPolicy} from './Component/PrivacyPolicy.jsx';
import {Documentation} from './Component/Documentation.jsx';
import {MakeCommandDocumentation} from './Component/MakeCommandDocumentation.jsx';
import {VideoCommandDocumentation} from './Component/VideoCommandDocumentation.jsx';
import {AudioCommandDocumentation} from './Component/AudioCommandDocumentation.jsx';
import {LipSyncDocumentation} from './Component/LipSyncDocumentation.jsx';
//import Navbar from './Component/NavbarMenu';
import { ChangelogDetailPage } from './Pages/ChangelogPage/changelogDetailPage.js';
import { AddChangelogEntryPage } from './Pages/ChangelogPage/addchangelogEntryPage.js';
import { EditChangelogEntryPage } from './Pages/ChangelogPage/editchangelogEntryPage.js';
import { LoraGuidePage } from './Component/LoraGuidePage.jsx';

import { Helmet } from 'react-helmet';

// Import the pages that we want to route to
import {SubChangeLandingPage,SubscriptionStatusPage, SuccessPage, DashboardPage, MenuPage, 
        SubscriptionSelectionPage, CheckoutPage, CreditPackageSelectionPage, CommandDashboardPage,
        ChangelogPage, PaymentHistoryPage } from "./Pages";

// Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_LIVE);

function App() {
    const location = useLocation();
    
    return (
        <CreditPackageProvider>
            <SubscriptionProvider>
                <Helmet>
                    <meta property="og:title" content="FLUX Development with Craftful.ai" />
                    <meta property="og:description" content="Join Craftful.ai, the ultimate platform for AI-driven media generation, now with FLUX development capabilities." />
                    <meta property="og:url" content="https://www.craftful.ai" />
                    <meta property="og:image" content="https://www.craftful.ai/cf-logo(192).png" />
                    <meta property="og:type" content="website" />
                </Helmet>
                <Routes>        
                    <Route path="/" element={<MenuPage />} />
                    <Route path="/menu" element={<MenuPage />} />
                    <Route path="/success" element={<SuccessPage key={location.key} />} />
                    <Route path="/subchangelanding" element={<SubChangeLandingPage key={location.key} />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
                    <Route path="/dashboard" element={<DashboardPage key={Date.now()} />} />
                    <Route path="/termsofservice" element={<TermsOfService/>} />
                    <Route path="/documentation" element={<Documentation/>} />
                    <Route path="/makecommanddocumentation" element={<MakeCommandDocumentation/>} />
                    <Route path="/videocommanddocumentation" element={<VideoCommandDocumentation/>} />
                    <Route path="/audiocommanddocumentation" element={<AudioCommandDocumentation/>} />
                    <Route path="/LipSyncDocumentation" element={<LipSyncDocumentation/>} />
                    <Route path="/loraDocumentation" element={<LoraGuidePage/>} />
                    <Route path="/privacypolicy2" element={<CommandDashboardPage/>} />
                    <Route path="/changelog/:title" element={<ChangelogDetailPage/>} />
                    <Route path="/changelog" element={<ChangelogPage/>} />
                    <Route path="/add-changelog-entry" element={<AddChangelogEntryPage />} />
                    <Route path="/changelog/edit/:id" element={<EditChangelogEntryPage />} />
                    <Route path="/paymenthistory" element={<PaymentHistoryPage />} />
                    
                    {/*<Route path="/wordify" element={<WordifyPage />} />*/}
                    {/*<Route path="/androidlarge1" element={<AndroidLarge1 />} />*/}

                    <Route path="/pay" element={
                        <Elements stripe={stripePromise}>
                            <SubscriptionSelectionPage />
                        </Elements>} 
                    />

                    <Route path="/subscription" element={
                        <Elements stripe={stripePromise}>
                            <SubscriptionSelectionPage />
                        </Elements>} 
                    />

                    <Route path="/credits" element={
                        <Elements stripe={stripePromise}>
                            <CreditPackageSelectionPage />
                        </Elements>} 
                    />
                    <Route path="/checkout" element={
                        <Elements stripe={stripePromise}>
                            <CheckoutPage />
                        </Elements>} 
                    />
                    <Route path="/subscriptionstatus" element={
                        <Elements stripe={stripePromise}>
                            <SubscriptionStatusPage />
                        </Elements>}
                    />
                </Routes>
            </SubscriptionProvider>
        </CreditPackageProvider>
    );
}

export default App;
