import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {GetVersion } from '../../Utils/api.js';
import './index.css';

const images = [
  "/thankyou1.png",
  "/thankyou2.png",   
  "/thankyou3.png",
  "/thankyou4.png",
];

const randomImage = images[Math.floor(Math.random() * images.length)];


export function SubChangeLandingPage() {
  const location = useLocation();
  const navigate = useNavigate();
  
  const [successMessage] = useState(location.state.successMessage);
  const [upordowngrade] = useState(location.state.upordowngrade);
  const [packageType] = useState(location.state.packageType);
  const [creditedAmount] = useState(location.state.creditedAmount);


  const goToDashboard = () => {
    navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`); // If the user is logged in, redirect them to the dashboard page
  };

  useEffect(() => {
    console.log("Version is: ",GetVersion());
  
      //console.log(`Arrived in SubChangeLandingPage`);
    
  }, [location.state, navigate ]);

  return (
    <div className="backgroundImage" style={{ backgroundImage: `url(${randomImage})` }}>
      <div className="content-wrapper" style={{ marginTop: '30px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
        <div className="confirmation-card" style={{ width: '50%', textAlign: 'center', border: '1px solid black', borderRadius: '10px', padding: '20px', backgroundColor: 'white' }}>
          <h1>{successMessage}</h1>
          <h2>{upordowngrade == "up"?'Upgrading to':'Downgrading to'}</h2>
          <h3>{packageType}</h3>
          <h3>{upordowngrade == "down"?'You account has been credited:':'You were charged:'}${creditedAmount/100.0}</h3>
        </div>
        <button 
          id="goToDashboardButton" 
          style={{fontSize: "20px", marginTop: '20px'}} 
          onClick={goToDashboard}>
          Back to Dashboard
        </button>
      </div>
      <p> version is: {GetVersion()}</p>
    </div>
  );
}


