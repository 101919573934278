// InvoiceModal.jsx
import React, { useEffect, useRef } from 'react';
import styles from './InvoiceModal.module.css';

export function MrInvoice({ invoice, onClose }) {
  const modalRef = useRef();

  useEffect(() => {
    console.log("Invoice is: ", invoice);

    function handleKeyDown(event) {
      if (event.keyCode === 27) { // ESC key
        onClose();
      }
    }
  
    function handleBackdropClick(event) {
      if (modalRef.current && event.target === modalRef.current) {
        onClose();
      }
    }
  
    // Add event listeners for keydown and click
    document.addEventListener('keydown', handleKeyDown);
    if (modalRef.current) {
      modalRef.current.addEventListener('click', handleBackdropClick);
    }
  
    // Set focus to the modal for accessibility
    if (modalRef.current) {
      modalRef.current.focus();
    }
  
    // Cleanup event listeners
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      if (modalRef.current) {
        modalRef.current.removeEventListener('click', handleBackdropClick);
      }
    };
  }, [onClose]);

  // Print handler function
  const handlePrint = () => {
    window.print();
  };

  // Don't render the modal if there is no invoice data
  if (!invoice) {
    return null;
  }

  return (
    <div className={styles.modalBackdrop} role="dialog" aria-modal="true" tabIndex="-1" ref={modalRef}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <h2>{invoice.invoice_pdf ? "Invoice Details" : "Charge Details"}</h2>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.modalContent}>
          <p><strong>Invoice Number:</strong> {invoice.id}</p>
          <p><strong>Date:</strong> {new Date(invoice.date * 1000).toLocaleDateString()}</p>
          <p><strong>Amount:</strong> ${(invoice.amount / 100).toFixed(2)}</p>
          <p><strong>Status:</strong> {invoice.status}</p>
          <p><strong>Type:</strong> {invoice.type}</p>
          <p><strong>Description:</strong> {invoice.description}</p>
          {invoice.invoice_pdf ? (
            <p>
              <strong>Full Invoice PDF:</strong> <a href={invoice.invoice_pdf} target="_blank" rel="noopener noreferrer">Download</a>
            </p>
          ) : (
            <p>
              <strong>Full Receipt:</strong> <a href={invoice.receipt_url} target="_blank" rel="noopener noreferrer">View</a>
            </p>
          )}
        </div>
        <div className={styles.modalFooter}>
          <button className={styles.actionButton} onClick={onClose}>Close</button>
          <button className={styles.actionButton} onClick={handlePrint}>Print</button>
        </div>
      </div>
    </div>
  );
}
