import React, { useEffect, useState } from 'react';
import SubscriptionContext from './SubscriptionContext';

export const SubscriptionProvider = ({ children }) => {
  const [subscriptionTiers, setSubscriptionTiers] = useState([]);

  useEffect(() => {
    fetch('/subscription_details.json')
      .then(response => response.json())
      .then(data => setSubscriptionTiers(data));
  }, []);

  return (
    <SubscriptionContext.Provider value={subscriptionTiers}>
      {children}
    </SubscriptionContext.Provider>
  );
};
