import React, { useEffect, useState, useMemo, useContext } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { useResponsiveFontSize, processSinglePayment, processSubscriptionPayment,
          verifyCoupon,getSubscriptionDetails, GetVersion } from '../../Utils/api.js';
import SubscriptionContext from '../../Component/SubscriptionContext';
import CreditPackageContext from '../../Component/CreditPackageContext';
import { states, countries } from './StatesAndCountries.js';
import axios from 'axios';
import './index.css';

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#9e2146"
        }
      }
    }),
    [fontSize]
  );

  return options;
};

export function CheckoutPage() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();
  const options = useOptions();
   
  const [paymentIntent] = useState(location.state.paymentIntent);
  const [selectedTierIndex] = useState(location.state.selectedTierIndex);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [key, setKey] = useState(Math.random());
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("AL");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("US");
  const [packageType, setPackageType] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [theCouponMessage, setTheCouponMessage] = useState("     ");

  const subscriptionTiers = useContext(SubscriptionContext);
  const creditPackages = useContext(CreditPackageContext);

  const selectedTier = subscriptionTiers[selectedTierIndex];
  const selectedCreditPackage = creditPackages[selectedTierIndex];

  const [isCustomerDetailsFetched, setCustomerDetailsFetched] = useState(false);

  // If we don't have a paymentIntent, then we need to go back to the dashboard
  useEffect(() => {
    console.log("Version is: ",GetVersion());
    console.log("tier ptype: ", selectedTier.ptype);
    if (!paymentIntent) {
      navigate(`/dashboard?${process.env.REACT_APP_CURRENT_VERSION}`);
    } else {     
      // Set the package type
      setPackageType(paymentIntent.metadata.payment_type);
  
      // Fetch customer details if we haven't already done so
      if (!isCustomerDetailsFetched)
        fetchCustomerDetails();
  
      setSucceeded(false);
    }
  }, [paymentIntent, navigate, isCustomerDetailsFetched,selectedCreditPackage.name,selectedTierIndex]);


  // Fetch's the customer details and populates the all the user details box's
  const fetchCustomerDetails = async () => {
    try {
        const { data: customer } = await axios.get(`/api/payment/customer/${paymentIntent.customer}`);

        setCustomerId(customer.id);

        // Populate state variables with customer details(the user details box's)
        
        setName(customer.name.trim() || "");
        setEmail(customer.email.trim() || "");
        setAddress(customer.address?.line1.trim() || "");
        setCity(customer.address?.city.trim() || "");
        setState(customer.address?.state.trim() || "AL");
        setPostalCode(customer.address?.postal_code.trim() || "");
        setCountry(customer.address?.country.trim() || "US");

        console.log(customer.name.trim());
        console.log(customer.email.trim());
        console.log(customer.address?.line1.trim());
        console.log(customer.address?.city.trim());
        console.log(customer.address?.state.trim());
        console.log(customer.address?.postal_code.trim());
        console.log(customer.address?.country.trim());
        

        // Indicate that we have fetched the customer details
        setCustomerDetailsFetched(true);
    } catch (error) {
        console.error("SubscriptionSelectionPage: Error fetching customer details: " + error);
    }
};

  // Listen for changes in the CardElement, which happens when the user inputs their card details
  const handleChange = async (event) => { 
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

 // When the user clicks the pay button, we need to create a payment method and send it to the api
 const handleSubmit = async ev => {
  ev.preventDefault();
  setProcessing(true);

  // If the user entered in a coupon code then first verify the code
  if (couponCode !== "") {
    try {
      const { data: coupon } = await verifyCoupon(couponCode);
      if (coupon.valid) {
        setTheCouponMessage("Coupon Accepted");
        setIsCouponValid(true);
      } else {
        setTheCouponMessage("Invalid Coupon Entered");
        setIsCouponValid(false);
        setProcessing(false);
        setCouponCode("");
      }
      
    } catch (error) {
      console.error(`Error verifying coupon: ${error.message}`);
      setTheCouponMessage("Invalid Coupon Entered");
      setCouponCode("");
      setIsCouponValid(false);
      setProcessing(false);
    }
  }


  // Grab the user details from the state variables
  const billingDetails = {
    name,
    email,
    address: { line1: address, city, state, postal_code: postalCode, country }
  };

  await axios.put(`https://www.CraftFul.ai/api/payment/customer/${customerId}`, {
    name: name,
    email: email,
    address: {
      line1: address,
      city: city,
      state: state,
      postal_code: postalCode,
      country: country
    }
  });

  // Create a payment method
  const { paymentMethod, error: pmError } = await stripe.createPaymentMethod({
    type: 'card',
    card: elements.getElement(CardElement),
    billing_details: billingDetails
  });

  if (pmError) {
    handleError(`Payment method creation failed: ${pmError.message}`);
    return;
  }


  try {
    let {data} = await processPayment(paymentIntent.id, paymentMethod.id, couponCode);

    
    if (data.status === 'succeeded') {
        if (paymentIntent.metadata.payment_type === "Credits")
          handleSuccess(data, {type: "Credits", 
                              name: selectedCreditPackage.name, 
                              price: data.amountPaid / 100.0,
                                discountName: data.discountName,
                                details: selectedCreditPackage.details}  );
        else
        if (paymentIntent.metadata.payment_type === "Subscription")
        {
          // Grab the actual price paid for the subscription(as a discount may have been applied)
          const { data: subscriptionData } = await getSubscriptionDetails(customerId);
          if (!subscriptionData)
            {
              handleSuccess(data, {type: "Subscription",
                            name: selectedTier.tier,
                              discounted: false,
                              price: selectedTier.price, 
                              details: selectedTier.details});
            }
          else
            {
              handleSuccess(data, {type: "Subscription",
              name: selectedTier.tier, 
                discounted: subscriptionData.discounted,
                price: subscriptionData.actualPrice / 100.0, 
                details: selectedTier.details});
            }
        }
        else
        console.error("Paymentintent had an invalid payment_type");
      } else if (data.status === 'requires_action') {
      handleCardAuthentication(data, paymentMethod, paymentIntent);
    } else {
      handleError(`Payment failed: ${data.error}`);
    }
  } catch (error) {
    handleError(`Payment failed: ${error.response ? error.response.data.message : 'Something went wrong'}`);
  }
};




async function processPayment(paymentIntentId, paymentMethodId, couponCode) {
  if (paymentIntent.metadata.payment_type === "Credits") {
    return await processSinglePayment(paymentIntentId, paymentMethodId, couponCode);
  }
  return await processSubscriptionPayment(paymentIntentId, paymentMethodId, couponCode);
}




async function handleSuccess(data, productInfo, subscriptionId) {
  setError(null);
  setSucceeded(true);

  if (!subscriptionId)
    console.error(`Subscription ID is null`);
  else
  {
    console.error(`Subscription ID is NOT NULL`);
    // Check the subscription status from the server
    try {
      const response = await axios.post('https://www.CraftFul.ai/api/payment/check-subscription-status', {
        subscriptionId: subscriptionId
      });

      const subscription = response.data;
    } catch (error) {
      console.error(`Error fetching subscription status: ${error}`);
    }
  }

  navigate(`/landing?${process.env.REACT_APP_CURRENT_VERSION}`, { state: { selectedTierIndex: selectedTierIndex, paymentIntent: data, whatwasbought: productInfo } });
}





async function handleCardAuthentication(data, paymentMethod, originalPaymentIntent) {
  const { error: errorAction, paymentIntent: newPaymentIntent } = await stripe.confirmCardPayment(data.client_secret);

  if (errorAction) {
    handleError(`Authentication failed: ${errorAction.message}`);
  } else {

    console.log("Card authentication succeeded with data: ", data);
    console.log("paymentintent amount: ",newPaymentIntent.amount);
    if (newPaymentIntent.status === 'succeeded') {
      let productInfo;
      if (originalPaymentIntent.metadata.payment_type === "Credits") {
        productInfo = {
          type: "Credits",
          name: selectedCreditPackage.name,
          price: newPaymentIntent.amount / 100.0,
          discountName: data.discountName,
          details: selectedCreditPackage.details
        };
      } else if (originalPaymentIntent.metadata.payment_type === "Subscription") {
        productInfo = {
          type: "Subscription",
          name: selectedTier.tier,
          price:  newPaymentIntent.amount / 100.0,
          discountName: data.discountName,
          details: selectedTier.details
        };
      } else {
        productInfo = {
          type: "Unknown",
          name: "Unknown",
          price: "Unknown",
          details: "Unknown"
        };
      }

      // Call handleSuccess with the subscription ID
      handleSuccess(newPaymentIntent, productInfo, originalPaymentIntent.metadata.subscription_id);
    } else {
      handleError(`Payment failed after authentication.`);
    }
  }
}



function handleError(errorMessage) {
  console.log(errorMessage);

  // Reset the CardElement when a payment method creation fails, so that the user can try again without
  // having to clear the card number out.
  setKey(Math.random());  
  setError(errorMessage);
  setProcessing(false);
}





return (
  <div className="checkout">
    <div className="checkout-form-container">
      <form className="checkout-form" onSubmit={handleSubmit}>
        <h3>Enter Payment Details:</h3>
        <div className="form-row">
          <div className="form-grid">
            <div className="left-side">
              <label htmlFor="name">Full Name</label>
              <input id="name" type="text" value={name} onChange={(e) => setName(e.target.value)} required />

              <label htmlFor="email">Email Address</label>
              <input id="email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} required />

              <label htmlFor="address">Address</label>
              <input id="address" type="text" value={address} onChange={(e) => setAddress(e.target.value)} required />

              <label htmlFor="city">City</label>
              <input id="city" type="text" value={city} onChange={(e) => setCity(e.target.value)} required />

              <label htmlFor="state">State</label>
              <select id="state" value={state} onChange={(e) => setState(e.target.value)} required>
                {states.map((s, index) => (
                  <option key={index} value={s.abbreviation}>{s.abbreviation} - {s.name}</option>
                ))}
              </select>

              <label htmlFor="postal_code">Postal Code</label>
              <input id="postal_code" type="text" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} required />

              <label htmlFor="country">Country</label>
              <select id="country" value={country} onChange={(e) => setCountry(e.target.value)} required>
                {countries.map((c, index) => (
                  <option key={index} value={c.abbreviation}>{c.abbreviation} - {c.name}</option>
                ))}
              </select>

              <label htmlFor="couponCode">Coupon Code</label>
              <input 
                  id="couponCode" 
                  type="text" 
                  value={couponCode} 
                  placeholder="Enter Coupon Code" 
                  onChange={(e) => setCouponCode(e.target.value)}
              />

              <label htmlFor="couponMessage" className={`coupon-message-label ${!isCouponValid ? 'invalid-coupon' : 'valid-coupon'}`}>{theCouponMessage}</label>
              <div className="coupon-message-space"></div>
                    
            </div>

            <div className="right-side">
              {packageType === 'Subscription' ? (
                <div className={`tier-card ${selectedTier.tier.toLowerCase()}`} style={{backgroundColor: selectedTier.color}}>
                  <h3>{selectedTier.tier}</h3>
                  <p>Price: {selectedTier.price} /{selectedTier.ptype}</p>
                </div>
              ) : (
                <div className={`package-card ${selectedCreditPackage.name.toLowerCase()}`} style={{backgroundColor: selectedCreditPackage.color}}>
                  <h3>{selectedCreditPackage.name}</h3>
                  <p>Price: {selectedCreditPackage.price}</p>
                </div>
              )}
            </div>
          </div>

          <label htmlFor="card-element">Card Number</label>
          <CardElement id="card-element" options={options} onChange={handleChange} key={key} />
          <div className="card-errors" role="alert">{error}</div>
          
        
        </div>

        <button disabled={processing || disabled || succeeded} id="submit">
          <span id="button-text">
            {processing ? 
              <div className="fullscreen-spinner">
                <div className="spinner" id="spinner"></div>
              </div>
              : 
              "Pay now"
            }
          </span>
        </button>
        <div className="terms-of-service-agreement">
            By continuing, you agree to our <Link to="/termsofservice">Terms of Service</Link>.
        </div>
        <div className="privacy-policy">
            <Link to="/privacypolicy">Privacy Policy</Link>
        </div>
      </form>
    </div>
    
  </div>
);

};

