import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './editChangelogEntryPage.module.css';

function SharedEditor({ content, setContent, modules }) {
    return (
        <div className={styles.inputGroup}>
            <label>Text:</label>
            <ReactQuill value={content} onChange={setContent} modules={modules} />
        </div>
    );
}

export default SharedEditor;
