import React, { useMemo, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addEntryAsync } from '../../Component/Redux/changelogSlice.js';
import { createModulesConfig } from './quillSetup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './editChangelogEntryPage.module.css';
import { getAuthorizationLevel } from '../../Utils/api.js';
import backButtonImage from '../../assets/backarrow.png';


export function AddChangelogEntryPage() {
    const [changeTitle, setChangeTitle] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [authorizationLevel, setAuthorizationLevel] = React.useState(null);
    const [backgroundColor, setBackgroundColorState] = useState('#FFFFFF');
    const [invalidTitle, setInvalidTitle] = useState(false);

    const isValidTitle = (title) => {
        try {
            decodeURIComponent(title);
            return true;
        } catch (e) {
            return false;
        }
    };

    useEffect(() => {
        const customButton = document.createElement('button');
        customButton.className = 'ql-set-bg-color ql-bold';
        customButton.onclick = () => {
            console.log("Here inside of customButton.onclick with color: ", backgroundColor);
            setBackgroundColorState(backgroundColor);
        }

        const toolbarContainer = document.querySelector('.ql-toolbar');
        if (toolbarContainer) {
            toolbarContainer.appendChild(customButton);
        }

        const fetchData = async () => {
            const level = await getAuthorizationLevel();
            setAuthorizationLevel(level);
        }
        fetchData();
    }, [backgroundColor]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!isValidTitle(changeTitle)) {
            setInvalidTitle(true);
            return;
        }

        dispatch(addEntryAsync({ changeTitle, changeText: editorContent, backgroundColor }));
        setChangeTitle('');
        setEditorContent('');
        navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`);
    };

    const quillModules = useMemo(() => createModulesConfig(setBackgroundColorState), [setBackgroundColorState]);

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Add New Changelog Entry</h2>
            <form onSubmit={handleSubmit}>
                <div className={styles.inputGroup}>
                    <label>Title:</label>
                    {invalidTitle && <span style={{ color: 'red' }}>Invalid Title. Please enter a valid title.</span>}
                    <input
                        className={styles.input}
                        type="text"
                        value={changeTitle}
                        onChange={(e) => {
                            setInvalidTitle(false);
                            setChangeTitle(e.target.value);
                        }}
                    />
                </div>
                <div className={styles.inputGroup}>
                    <label>Text:</label>
                    <ReactQuill
                        value={editorContent}
                        onChange={setEditorContent}
                        modules={quillModules}
                    />
                </div>

                {authorizationLevel === "admin" && (
                    <button className={styles.button} type="submit">Add Entry</button>
                )}
                <div className={styles.buttoncontainer}>
                    <button className={styles.backButton} onClick={() => window.location.href = `https://www.CraftFul.ai/changelog?${process.env.REACT_APP_CURRENT_VERSION}`}>
                        <img src={backButtonImage} alt="Back" />
                    </button>
                </div>
            </form>
        </div>
    );
}
