import { Quill } from 'react-quill';
import axios from 'axios';

const FontSizes = ['8px','10px', '12px', '14px', '16px', '18px', '24px', '36px'];
const BlockEmbed = Quill.import('blots/block/embed');

const Size = Quill.import('attributors/style/size');
Size.whitelist = FontSizes;
Quill.register(Size, true);

class VideoBlot extends BlockEmbed {
  static create(value) {
    let node = super.create();
    node.setAttribute('src', value);
    node.setAttribute('controls', '');
    //node.setAttribute('autoplay', 'false');
    node.setAttribute('playsinline', '');
    return node;
  }

  static value(node) {
    return node.getAttribute('src');
  }
}

VideoBlot.blotName = 'video';
VideoBlot.tagName = 'video';
Quill.register(VideoBlot);

const API_ENDPOINT = 'https://www.CraftFul.ai/api/changelog/upload';

async function uploadImageToServer(formData) {
  try {
    const response = await axios.post(API_ENDPOINT, formData);
    return response.data.url;
  } catch (error) {
    console.error("Error uploading the image/video:", error);
    throw error;
  }
}

function imageHandler() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*,video/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    let fileType = 'image';  // default to image

    if (file.type.startsWith('video/')) {
        fileType = 'video';
    }

    const formData = new FormData();
    formData.append('image', file);

    const mediaUrl = await uploadImageToServer(formData);

    const range = this.quill.getSelection(true);
    this.quill.insertEmbed(range.index, fileType, mediaUrl);
    this.quill.setSelection(range.index + 1);
  }
}

function setBackgroundColor(callback) {
    let colorInput = document.createElement('input');
    colorInput.type = 'color';
    colorInput.value = "#FFFFFF";
    document.body.appendChild(colorInput);

    //let picker = new jscolor(colorInput);

    colorInput.addEventListener('change', function() {
      let selectedColor = this.value;

      const quillEditorElement = document.querySelector('.ql-editor');
      if (quillEditorElement) {
          quillEditorElement.style.setProperty('background-color', selectedColor, 'important');
      }

      if(typeof callback === "function") {
          callback(selectedColor);
      } else {
          console.error('Provided callback is not a function:', callback);
      }

      colorInput.remove();
    });

    colorInput.click();
}


function createModulesConfig(callback) {
  console.log("Inside createModulesConfig with callback: ");
  return {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }], 
        [{ 'size': FontSizes }],
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['code-block'],
        ['clean']
      ],
      handlers: {
        image: imageHandler,
        video: imageHandler,
        'code-block': () => setBackgroundColor(callback)
      }
    }
  };
}

export { createModulesConfig, uploadImageToServer };
