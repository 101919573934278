import React from 'react';
import './TermsOfService.css';

export const TermsOfService = () => {
    return (
          <div className="container">
            <div className="title">
                <h1>Terms of Use Agreement</h1>
                <p>Last Updated Date: Aug 22, 2023</p>
            </div>
            <p>We welcome you to the CraftFul.AI platform, offering innovative services such as Text to Video, Text to Images, Text to Audio, and LipSync technologies (collectively referred to as the “Services”). The following Terms of Service (the “Agreement”) define your rights regarding the creation, access, and distribution of content and assets through our Services (the “Assets”). We encourage you to read this Agreement with care. To learn about our data handling practices, please consult our <a href="privacypolicy">privacy policy</a>.</p>
            <h2>1. Availability and Quality of Service</h2>
            <p>CraftFul.AI is committed to ongoing innovation and refinement of the Services, including Text to Video, Text to Images, Text to Audio, and LipSync technologies. Alterations and modifications may occur in various aspects of the Services, with no guarantees regarding quality, stability, availability, or dependability. CraftFul.AI disclaims liability for any damages resulting from dependencies on specific attributes of the Services or Assets. Restrictions, warranties, and other conditions as previously described apply to all aspects of the Services.</p>
            <h3>1.1 LABS Feature</h3>
            <p>CraftFul.AI introduces the rotating feature "LABS," where we periodically add and remove specific features to experiment and enhance user experience. Features within LABS are experimental and subject to modification, discontinuation, or removal at our sole discretion. Customers may explore LABS features but should not rely on them for critical or ongoing projects.</p>
            <h3>1.2 Changing Assets and Models</h3>
            <p>As part of our continuous effort to innovate, CraftFul.AI may change or update the Assets and models used within the Services. This includes adjustments to art styles, algorithms, and other attributes related to Asset creation and manipulation. These changes may affect the appearance, functionality, or behavior of existing and new Assets. Customers should be aware that these changes are part of our commitment to improving the Services and may occur without prior notice.</p>
            <p>The Services, Assets, LABS features, and changes to Assets and models are furnished on an "AS IS" basis, without any express or implied warranties. CraftFul.AI reserves the right to scrutinize grievances, restrict or terminate access, and take appropriate actions as described in earlier sections.</p>
            <h2>2. Age Restrictions</h2>
            <p>When engaging with CraftFul.AI's Services, You certify that You have reached the age of 13 or above and satisfy the legal age for digital engagement in Your country of residence. Should You fulfill the age prerequisites for utilizing the Services but lack the legal age to consent to these provisions, then a parent or legal custodian must accept our conditions on Your behalf.</p>
            <p>Minors are advised to consult these terms with a parent or legal custodian. If You are in the position of a parent or lawful guardian allowing a minor to access the Services, You must be aware that these terms are binding on You, and You will be accountable for the minor's conduct on the Services.</p>
            <p>CraftFul.AI is committed to offering a PG-13 and family-accessible environment, but it is essential to recognize that the Assets are crafted by an AI system in response to user inputs. This cutting-edge technology might not always behave predictably. Therefore, CraftFul.AI cannot assure the appropriateness of the Assets for each individual or all scenarios.</p>
            <h3>2.1 Horror and Gore Channel Age Restrictions</h3>
            <p>CraftFul.AI's Services include specialized channels catering to different content genres, including a channel specifically dedicated to horror and gore content. Access to this channel is strictly limited to individuals who are 18 years of age or older.</p>
            <p>This restriction reflects our commitment to responsible content delivery and our awareness of the sensitive nature of horror and gore content. By enforcing an age limit, we aim to ensure that this content is only accessible to those who are of legal adult age in their respective jurisdictions.</p>
            <p>If You wish to access this specific channel, You must verify that You meet the age requirement and acknowledge the explicit and potentially disturbing nature of the content within. This channel's content may include intense imagery, themes, or concepts that are not suitable for a younger or more sensitive audience.</p>
            <p>Parents and guardians are strongly encouraged to be aware of and monitor their children's usage of CraftFul.AI's Services, particularly in relation to this age-restricted channel.</p>
            <p>Please be advised that violation of these age restrictions may result in immediate suspension or termination of access to the Services. CraftFul.AI reserves the right to take additional actions as deemed necessary to enforce these restrictions.</p>
            <h2>3. RESPONSIBILITY FOR CONTENT</h2>
            <p>You recognize that all images, video, audio, audio-visual, text, materials, and other content are solely the responsibility of the person or entity from whom such Content originated. This means that you, and not CraftFul, bear the full responsibility for all Content that you upload, post, share, or otherwise make available through CraftFul's platform. Likewise, you and other Registered Users of CraftFul's platform, and not CraftFul, are accountable for all Content that you and they Make Available through CraftFul's platform. Your Content also includes any Content that you create or generate through the use of CraftFul's platform, excluding CraftFul's proprietary properties.</p>
            <h3>3.1 No Obligation to Pre-Screen Content</h3>
            <p>You understand that CraftFul has no obligation to pre-screen Content (including, but not limited to, User Content), although CraftFul reserves the right in its sole discretion to pre-screen, refuse, or remove any Content. By entering into this Agreement, you grant your irrevocable consent to such monitoring. You acknowledge and agree that you have no expectation of privacy regarding the transmission of Your Content, including without limitation chat, text, or voice communications. Should CraftFul pre-screen, refuse or remove any Content, you recognize that CraftFul will do so for its benefit and not yours. Without limiting the foregoing, CraftFul shall have the right to remove any Content that breaches the Agreement or is otherwise unacceptable.</p>
            <h3>3.2 Storage</h3>
            <p>Unless expressly agreed upon by CraftFul in writing, CraftFul has no obligation to store any of Your Content that you Make Available on CraftFul's platform. CraftFul has no responsibility or liability for the deletion or accuracy of any Content, including Your Content; the failure to store, transmit, or receive transmission of Content; or the security, privacy, storage, or transmission of other communications originating from or involving the use of CraftFul's platform. CraftFul is not liable for any content that is in the queue to be made and is lost due to the failure of the bot, or having to restart services, and the user takes responsibility for starting over. You agree that CraftFul retains the right to establish reasonable limits on CraftFul's use and storage of the Content, including Your Content, such as limits on file size, storage space, processing capacity, and similar limits described on the website and as otherwise determined by CraftFul in its sole discretion.</p>
            <h2>4. Copyright, Trademark, and Ownership</h2>
            <p>In this section, Paid Member shall refer to a Customer who has subscribed to a premium plan.</p>
            <h3>Rights You Grant to CraftFul.AI</h3>
            <p>When utilizing the Services, You bestow upon CraftFul.AI, along with its successors and permitted assigns, a continuous, global, non-exclusive, sublicensable, royalty-free, irrevocable license to replicate, modify, publicly exhibit, publicly perform, sublicense, and disseminate the text, image prompts You insert into the Services, or Assets crafted by the service at Your instruction. This license continues even after the termination of this Agreement by any party, for any reason.</p>
            <h3>Your Rights</h3>
            <p>Aside from the aforementioned license, You retain ownership of all Assets You generate through the Services, as long as they were fabricated following this Agreement. This does not include the alteration of images created by others, which remain the property of the original creators. CraftFul.AI provides no guarantees or statements regarding laws that may apply to You. It is advised to consult with a legal professional for specific information on the prevailing laws in Your area. Your entitlement to the Assets you fabricated remains intact, even if You later downgrade or terminate Your membership.</p>
            <h3>4.1 Use of Your Creations by Others</h3>
            <p>CraftFul.AI fosters an open and collaborative environment that enables others to use, make variations of, and interact with your images, videos, audio files, text-to-video creations, prompts, and any other assets you post in a public setting. By default, your creations are publicly accessible and open for reinterpretation.</p>
            <p>As outlined previously, you grant CraftFul.AI the right to facilitate this level of interaction and engagement with your creations. This license encapsulates the sharing and making variations of a diverse array of your contributions, empowering the CraftFul.AI community to innovate and build upon your original ideas.</p>
            <p>It's important to recognize that participation in this open platform involves both opportunities and responsibilities. While your creations can inspire and be reshaped by others, you must also respect the intellectual property and creative rights of fellow community members.</p>
            <h3>4.2 Use of Others' Creations for Commercial Use</h3>
            <p>While CraftFul.AI encourages the creative community to use and make variations of others' creations within our platform, it is crucial to recognize that ownership of these original creations remains with the individual creators. Their intellectual property rights are protected and must be respected at all times.</p>
            <p>If you wish to use or make variations of another user's creation for commercial purposes, you must first obtain explicit written consent from the original creator. Commercial use without such authorization is strictly prohibited and may result in legal consequences.</p>
            <p>At CraftFul.AI, we foster a spirit of collaboration and innovation but also emphasize the importance of ethical practices and adherence to legal standards. It's your responsibility to ensure that your commercial utilization of others' creations complies with all applicable laws and respects the rights of the original creators.</p>
            <p>Please reach out to the original creator through the appropriate channels provided by CraftFul.AI if you are interested in using their work commercially. Always ensure proper attribution, follow the agreed-upon terms, and act in accordance with the principles of fairness and respect that guide our community.</p>
            <h2>5. Payment and Billing</h2>
            <p>CraftFul.AI provides the Services to You, and the invoicing for your use may be handled by an authorized third-party payment processor. In the event of any conflict between this Agreement and the terms provided by the third-party payment processor, the latter's terms will take precedence and govern.</p>
            <p>You have the flexibility to cancel your subscription plan with us at any moment. However, CraftFul.AI retains the authority to revoke your access to the Services if we detect any breach of our Community Guidelines or any other misuse of the Service. Engaging in activities that violate our Community Guidelines is considered a breach of this Agreement, and no refunds will be provided for the ongoing subscription period in such cases.</p>
            <p>Once your current subscription period concludes, you will not incur any additional charges if you have already canceled your plan.</p>
            <h3>5.1 Fees and Purchase Terms</h3>
            <p>By utilizing CraftFul.AI's Services, you consent to pay all applicable fees or charges to your Account, in line with the fees, charges, and billing terms active at the time such fee or charge is payable. To sign up for the Services, you must provide CraftFul.AI with a valid credit card (such as Visa, MasterCard, or any other issuer accepted by us) via a recognized Payment Provider, or with accurate purchase order information.</p>
            <p>Your relationship with the Payment Provider and the use of the selected credit card is governed by your agreement with them; refer to that agreement, not this one, for details on your rights and obligations. By furnishing CraftFul.AI with your credit card details and related payment information, you are granting us the authorization to promptly invoice your Account for all fees and charges that are due and payable to CraftFul.AI, without needing further notice or consent.</p>
            <p>Please be sure to promptly inform CraftFul.AI of any alterations in your billing address or the credit card you are utilizing for payment. CraftFul.AI retains the right to modify its pricing and billing practices at any given time, with immediate effect upon either posting on CraftFul.AI's properties or through email notification to you.</p>
            <h3>5.2 AUTOMATIC RENEWAL AND CANCELLATION</h3>
            <p>Your subscription with CraftFul.AI will persist indefinitely until terminated in compliance with this Agreement. Following your initial subscription term, and subsequently after any recurring subscription term, your subscription will automatically renew on the first day succeeding the end of that period (each identified as a “Renewal Commencement Date”) and extend for an additional equivalent period, at CraftFul.AI's then-current pricing for that specific subscription.</p>
            <p>You acknowledge that your Account will be subject to this automatic renewal feature. Should you wish to prevent automatic renewal, you must cancel your subscription at least thirty (30) days prior to the Renewal Commencement Date.</p>
            <h3>5.3 CANCELLATIONS AND REFUNDS</h3>
            <p>CraftFul.AI operates on a no-refund policy. All cancellations will be honored only at the conclusion of the current billing cycle. It is the responsibility of the user to ensure that they are purchasing the services they intend to use and to cancel their subscription prior to being re-billed.</p>
            <p>If a user cancels on the first day of being re-billed, their account will remain active until the end of that billing cycle, and cancellation will take effect thereafter. No refunds will be provided for any subscription fees already billed or paid, regardless of the usage or cancellation date within the billing cycle.</p>
            <p>CraftFul.AI reserves the right to terminate your subscription at any time, with or without cause. In the event of such termination, you will not be entitled to a refund for any fees or charges, including any unused portion of your subscription fee.</p>
            <h2>6. DMCA and Takedowns Policy</h2>
            <h3>Notification Procedures</h3>
            <p>CraftFul.AI is committed to respecting the intellectual property rights of others. Should you believe that material located on or linked to our Services infringes upon your copyright or trademark, please send a notice of claimed infringement to <a href="mailto:general@CraftFul.ai">general@CraftFul.ai</a> with the subject “DMCA REQUEST,” and include the following:</p>
            <ul>
                <li>Your physical or electronic signature.</li>
                <li>Identification of the copyrighted work (or mark) you believe to have been infringed, or if the claim involves multiple works, a representative list of such works.</li>
                <li>Identification of the material you believe to be infringing in a manner detailed enough to allow us to locate that material.</li>
                <li>Adequate information by which we can contact you (including your name, postal address, telephone number, and, if available, email address).</li>
                <li>A statement that you have a good faith belief that the use of the copyrighted material is not authorized by the copyright owner, its agent, or the law.</li>
                <li>A statement that the information in the written notice is accurate.</li>
                <li>A statement, under penalty</li></ul>
            <address>
            CraftFul.AI, Inc.<br />
            Attn: ADMIN Department<br />
            252 SW 12th Ave<br />
            Deerfield Beach, FL 33442<br />
            USA
            </address>
            <p>Upon receipt of a notice that complies with the above, we reserve the right to remove or disable access to the alleged infringing material, notify the accused party, and terminate access to and use of the Services for users found to repeatedly engage in infringement.</p>
            <p>Please be aware that knowingly misrepresenting material or activity as infringing may subject you to liability under Section 512(f) of the DMCA.</p>
            <h3>Counter-Notification Procedures</h3>
            <p>If you believe that material was removed or access disabled by mistake, you may file a counter-notification as described above.</p>
            <h3>Dispute Resolution and Governing Law</h3>
            <p>ALL LEGAL CLAIMS ARISING OUT OF OR RELATING TO THIS AGREEMENT ("Dispute") WILL BE GOVERNED BY THE LAWS OF THE STATE OF FLpute.</p>
            <p>Injunctive relief may be sought, and the arbitral award will be final and binding, enforceable in any competent court.</p>
            <h2>7. Community Guidelines:</h2>
            <p>User agrees to adhere to the <strong>DISCORD COMMUNITY GUIDELINES</strong>, which can be found at <a href="https://discord.com/guidelines" target="_blank" rel="noopener noreferrer">https://discord.com/guidelines</a>, while using our services on Discord.</p>
            <p>In addition, users must agree to follow the specific community rules located on our Discord server at <a href="https://discord.com/channels/972696810227372102/1103392401969647657" target="_blank" rel="noopener noreferrer">https://discord.com/channels/972696810227372102/1103392401969647657</a>.</p>
            <p>Failure to comply with these guidelines and rules may result in actions taken against the user's account, up to and including suspension or termination. By engaging with our community on Discord, you accept and commit to these terms and expectations.</p>
            <h2>8. Force Majeure.</h2>
            <p>Neither party shall be liable for any failure or delay in performance under this Agreement to the extent that such failures or delays are caused by circumstances beyond its reasonable control, including but not limited to acts of God, natural disasters, terrorism, riots, or war. This includes, without limitation, changes in laws, regulations, or government policies, strikes, power failures, electrical outages, interruptions in telecommunications services, errors or fluctuations in computer operation, or other occurrences that are beyond the party's reasonable control. Such non-performance shall not be a breach of this Agreement if the affected party makes reasonable efforts to perform.</p>
            <h2>9. Severability.</h2>
            <p>If any provision of this Agreement is found to be invalid or unenforceable by a court of competent jurisdiction, such provision shall be severed from the remainder of this Agreement, and such invalidity or unenforceability will not affect the other provisions of this Agreement. The remaining provisions will continue in full force and effect, and the invalid or unenforceable provision shall be deemed modified so that it is valid and enforceable to the maximum extent permitted by law, reflecting as closely as possible the original intention of the parties.</p>
            <h2>10. Limitation of Liability and Indemnity</h2>
            <p>Our service is provided "as is," and we do not make any explicit promises or guarantees about its operation. It's important for you to understand and agree that we shall not be held liable to You or any third party for any losses of profits, use, goodwill, or data, nor for any incidental, indirect, special, consequential, or exemplary damages, regardless of their origin.  You take full responsibility for your use of the service. In the event that your actions lead to harm to others or result in disputes, we will not be drawn into such matters.</p>
            <p>Should you knowingly infringe upon someone else's intellectual property rights, causing financial implications for us, we reserve the right to seek compensation from you. This may include pursuing legal action to recover our attorney's fees. We strongly advise against engaging in such behavior.</p>
            <h2>11. Entire Agreement.</h2>
            <p>This Agreement constitutes the final, complete, and exclusive agreement of the parties concerning the subject matter hereof, and supersedes and merges all prior agreements, understandings, and discussions between the parties relating to such subject matter. No modification of or amendment to this Agreement, nor any waiver of any rights under this Agreement, will be effective unless expressly agreed to in writing by both parties.</p>
          </div>
      );
};


