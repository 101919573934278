// ChangelogDetailPage.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { deleteEntryAsync } from '../../Component/Redux/changelogSlice.js';
import styles from './changelogDetailPage.module.css';
import pageStyles from './index.module.css'; // Importing the ChangelogPage's styles
import { getAuthorizationLevel } from '../../Utils/api.js';
import backButtonImage from '../../assets/backarrow.png';


export function ChangelogDetailPage() {
  const { title } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const entries = useSelector(state => state.changelog.entries);

  let entry;
  // Try to decode the title
  let decodedTitle;
  try {
    decodedTitle = decodeURIComponent(title);
    entry = entries.find(e => e.changeTitle === decodedTitle);

  } catch (error) {
    console.error('Failed to decode URI:', title, error);

    // Just the original title if decoding fails
    decodedTitle = title;
     
  }

  const [authorizationLevel, setAuthorizationLevel] = React.useState(null);

  // This function will set the user's authorization level based on the currently logged in user
  useEffect(() => {
      const fetchData = async () => {
          const level = await getAuthorizationLevel();
          setAuthorizationLevel(level);
      }
      fetchData();
  }, []);


  const handleDelete = () => {
    dispatch(deleteEntryAsync(entry.id));
    navigate(`/changelog?${process.env.REACT_APP_CURRENT_VERSION}`)
};

if (!entry) return <div>Entry not found</div>;

  return (
    <div className={pageStyles["change-log-page"]}>
        <Helmet>
            <title>{`Changelog: ${entry.changeTitle}`}</title>
            <meta name="description" content={`Details about changelog entry: ${entry.changeTitle}`} />
        </Helmet>

        <div className={pageStyles["entry-card"]}>
            <h2 className={styles.title}>{entry.changeTitle}</h2>
            <p className={pageStyles["date-time"]} style={{ color: '#FE78FD' }}>{entry.changeDate}</p>
            <p className={pageStyles["entry-text"]} 
                style={{ backgroundColor: entry.backgroundColor || 'transparent' }}
                dangerouslySetInnerHTML={{ __html: entry.changeText }}
            ></p>
        </div>

        {authorizationLevel === "admin" && (
        <div className={styles.buttonGroup}>
            <button onClick={() => navigate(`/changelog/edit/${entry.id}`)}>Edit</button>
            <button onClick={handleDelete}>Delete</button>
        </div>)}
        <div className={styles.buttoncontainer}>
          <button className={styles.backButton} onClick={() => window.location.href = `https://www.CraftFul.ai/changelog?${process.env.REACT_APP_CURRENT_VERSION}`}>
            <img src={backButtonImage} alt="Back" />
          </button>
        </div>

    </div>
  );
}
