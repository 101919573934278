import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchChangelogEntries } from '../../Component/Redux/changelogSlice.js';
import { Link, useNavigate } from 'react-router-dom';
import { getAuthorizationLevel } from '../../Utils/api.js';
import backButtonImage from '../../assets/backarrow.png';
import 'normalize.css';
import 'react-quill/dist/quill.snow.css';
import styles from './index.module.css';


export function ChangelogPage() {
  const entries = useSelector(state => state.changelog.entries);
  //const entryCount = useSelector(state => state.changelog.entryCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  
  // This variable will hold the user's authorization level
  const [authorizationLevel, setAuthorizationLevel] = React.useState(null);

  // This function will set the user's authorization level based on the currently logged in user
  useEffect(() => {
    const fetchData = async () => {
        const level = await getAuthorizationLevel();
        setAuthorizationLevel(level);
    }
    fetchData();
  }, []);

  useEffect(() => {
      console.log("ChangelogPage useEffect() called");
      //dispatch(fetchChangelogEntryCount());
      dispatch(fetchChangelogEntries());
  }, [dispatch]);


  return (
    <div className={styles["change-log-page"]}>
      <h2 onClick={() => window.location.href = `https://www.CraftFul.ai?${process.env.REACT_APP_CURRENT_VERSION}`}>ChangeLog</h2>
      {authorizationLevel === "admin" && (
        <button className={styles.addButton} onClick={() => navigate(`/add-changelog-entry?${process.env.REACT_APP_CURRENT_VERSION}`)}>
          Add Entry
        </button>
      )}
      <div className={styles["entries-list"]}>
      {entries && entries.length > 0 ? (
       entries.slice().reverse().map(entry => {
        const { id, changeDate, changeTitle, changeText, backgroundColor } = entry; // assuming 'backgroundColor' is the field where the color is stored
          
          if (!id || !changeDate || !changeTitle || !changeText) {
            console.warn(`Entry with ID ${id} has incomplete content.`);
            if (!id)
              console.warn(`Entry with ID ${id} is missing an ID.`);

            if (!changeDate)
              console.warn(`Entry with ID ${id} is missing a changeDate.`);

            if (!changeTitle)
              console.warn(`Entry with ID ${id} is missing a changeTitle.`);

            if (!changeText)
              console.warn(`Entry with ID ${id} is missing a changeText.`);
            
            return null; // skip rendering this entry
          }

          // Create a URL-friendly version of the changeTitle
          const entryLink = `/changelog/${encodeURIComponent(changeTitle)}`;
  
          return (
            <Link key={id} to={entryLink} className={styles["entry-card-link"]}>
              <div className={styles["entry-card"]}>  {/* style={{ backgroundColor: backgroundColor || '#68449c' }}*/}
                <h2 className={styles["change-title"]}>{changeTitle}</h2>
                <p className={styles["date-time"]} style={{ color: '#FE78FD' }}>{changeDate}</p>
                <p className={styles["entry-text"]} 
                   style={{ backgroundColor: backgroundColor || 'transparent' }}
                   dangerouslySetInnerHTML={{ __html: changeText }}>
                </p>
              </div>
            </Link>
          );
        })):(
          <div className={styles.noEntries}>
              Sorry, there are no entries to show right now.
          </div>
        )}  
      </div>
      
      <div className={styles.buttoncontainer}>
        <button className={styles.backButton} onClick={() => window.location.href = `https://www.CraftFul.ai?${process.env.REACT_APP_CURRENT_VERSION}`}>
          <img src={backButtonImage} alt="Back" />
        </button>
      </div>

      </div>
  );
}

