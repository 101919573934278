import React from 'react';
import './PrivacyPolicy.css';

export function PrivacyPolicy() {
    return (
        <div className="container">
            <h1 className="title">Privacy Policy for CraftFul.AI</h1>

            <h2>Introduction</h2>
            <p>CraftFul.AI ("we," "our," or "us") offers services including but not limited to Text to Video, Text to Images, Text to Audio, and LipSync on Discord. Our services are accessible through a free version offered via our Discord bot or through a subscription plan that grants expanded access. This Privacy Policy is designed to inform you about our practices concerning the collection, use, and disclosure of Personal Information that you may provide or that we may collect in connection with your use of these services.</p>
            <p>By choosing to utilize our Service, whether through the free version or subscription plan, you consent to the collection and use of information in accordance with this policy.</p>

            <h2>Information Collection and Use</h2>
            <p>To enhance our Service, we may require you to provide personally identifiable information, including but not limited to your name, email, and payment details. This information will be retained and used as described in this Privacy Policy.</p>
            <h3>Collection</h3>
            <p>We gather information from you when utilizing our services, including subscription details and user engagement.</p>
            <h3>Use</h3>
            <p>We exclusively use the collected data to improve our services and understand your preferences, allowing us to provide a more tailored experience.</p>

            <h2>Payment Processing</h2>
            <p>We use Stripe to manage subscription payments. All payment data is securely handled by Stripe, and we do not store or have direct access to your payment information.</p>

            <h2>Disclosure & Sharing</h2>
            <p>Data we collect is securely stored and shared only when necessary to enable the functionality of the Service's offerings.</p>

            <h2>Retention</h2>
            <p>Your data will be stored no longer than necessary for the purposes outlined in this Privacy Policy.</p>

            <h2>Security</h2>
            <p>We implement technical measures to ensure appropriate security, including protection against unauthorized or unlawful processing and accidental loss, destruction, or damage.</p>

            <h2>Cookies</h2>
            <p>Our Service may use third-party code and libraries that use cookies to enhance their services. You can accept or refuse these cookies.</p>

            <h2>Service Providers</h2>
            <p>We may employ third-party companies to facilitate our Service, provide the Service on our behalf, or analyze how our Service is used. These third parties have access to your Personal Information to perform assigned tasks but are obligated not to disclose or use it for other purposes.</p>

            <h2>Links to Other Sites</h2>
            <p>Our Service may contain links to other sites. We have no control over third-party sites and assume no responsibility for their content or practices.</p>

            <h2>Children's Privacy</h2>
            <p>Our Services are not intended for children under the age of 13. We do not knowingly collect personal data from children under 13, and we take additional measures to prevent such collection. If we discover that a child under 13 has provided personal information, we will take immediate steps to delete it from our servers. Parents or guardians who believe that their child has provided us with personal information are urged to contact us so we can take necessary actions.</p>

            <h2>Changes to This Privacy Policy</h2>
            <p>We may update this Privacy Policy periodically, so please review this page for any changes.</p>

            <h2>Contact Us</h2>
            <p>For questions or suggestions about our Privacy Policy, please contact us at <a href="mailto:general@craftful.ai">general@craftful.ai</a></p>
        </div>
    );
}

