import React, { useEffect, useState } from 'react';
import CreditPackageContext from './CreditPackageContext';

export const CreditPackageProvider = ({ children }) => {
  const [creditPackages, setCreditPackages] = useState([]);

  useEffect(() => {
    fetch('/credit_packages.json')
      .then(response => response.json())
      .then(data => setCreditPackages(data));
  }, []);

  return (
    <CreditPackageContext.Provider value={creditPackages}>
      {children}
    </CreditPackageContext.Provider>
  );
};

